import {Analytics, File, Home, House, Settings, UploadSquare, UserAccount, UserSquare} from "../components/Icons";

export interface Menu {
    title: string;
    path?: string;
    icon?: any;
    subMenus?: Menu[];
}

export default [
    {
        title: 'Home',
        path: '/home',
        icon: Home,
    },
    {
        title: 'Dashboard',
        path: '/dashboard',
        icon: Analytics,
    },
    {
        title: 'Cadastro de Cliente',
        path: '/clientes',
        icon: UserAccount,
    },
    {
        title: 'Cadastro de Usuário',
        path: '/usuarios',
        icon: UserSquare,
    },
    {
        title: 'Cadastro de Imóveis',
        path: '/propriedades',
        icon: House,
    },

    {
        title: 'Arquivos',
        path: '/pastas',
        icon: UploadSquare,
    },
    /*{
        title: 'Relatório',
        path: '/relatorios',
        icon: File,
    },*/
    {
        title: 'Configurações',
        path: '/configuracoes',
        icon: Settings,
    }
];