import * as React from "react";
import type { SVGProps } from "react";
const SvgDoc = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '1em'}
    height={props.height || '1em'}
    viewBox="0 0 20.045 22"
    {...props}
  >
    <g fill="none" stroke="CurrentColor" strokeLinecap="round" strokeWidth={2}>
      <path
        d="M19 13.016c-.046-.992-.723-1.016-1.643-1.016-1.416 0-1.651.338-1.651 1.667v1.667c0 1.328.235 1.667 1.651 1.667.92 0 1.6-.024 1.643-1.016M8.295 14.5A2.485 2.485 0 0 1 5.824 17a1.2 1.2 0 0 1-.576-.067c-.275-.16-.247-.485-.247-.766v-3.334c0-.281-.027-.606.247-.766A1.2 1.2 0 0 1 5.824 12a2.485 2.485 0 0 1 2.471 2.5ZM12 17c-.776 0-1.165 0-1.406-.244s-.241-.637-.241-1.423v-1.666c0-.786 0-1.179.241-1.423S11.224 12 12 12s1.165 0 1.406.244.241.637.241 1.423v1.667c0 .786 0 1.179-.241 1.423S12.776 17 12 17Z"
        data-name="Caminho 337"
      />
      <path
        strokeLinejoin="round"
        d="M13 21H8.727c-3.261 0-4.892 0-6.024-.8a4.1 4.1 0 0 1-.855-.805C1 18.331 1 16.8 1 13.727v-2.545c0-2.963 0-4.445.469-5.628a7.23 7.23 0 0 1 4.369-4.113C7.1 1 8.67 1 11.818 1a11.3 11.3 0 0 1 3.417.252 4.13 4.13 0 0 1 2.5 2.35A9.4 9.4 0 0 1 18 6.818V9"
        data-name="Caminho 338"
      />
      <path
        strokeLinejoin="round"
        d="M1 11a3.333 3.333 0 0 1 3.333-3.333 8.5 8.5 0 0 0 2.1-.057A1.67 1.67 0 0 0 7.61 6.431a8.5 8.5 0 0 0 .057-2.1A3.333 3.333 0 0 1 11 1"
        data-name="Caminho 339"
      />
    </g>
  </svg>
);
export default SvgDoc;
