import React, {useEffect, useState} from "react";

import Input from "../../../components/inputs/Input";
import {BsPlus} from "react-icons/bs";
import {IoIosClose} from "react-icons/io";
import {Checkbox} from "../../../components/inputs/Checkbox";
import documentTypeApi from "../../../services/documentTypeApi";
import typePropertyApi from "../../../services/typePropertyApi";
import Alert from "../../../helpers/Alert";
import InputSelect from "../../../components/inputSelect";

export function DocumentType({permissionEdit}: any) {
    const [state, setState]: any = useState([]);

    const [loading, setLoading] = useState(true);
    const getData = async () => {
        const {data, isError} = await documentTypeApi.listAll();
        if (!isError){
            if (data.length){
                setState(data)
            }else{
                setState([{
                    description: '',
                    status: true
                }])
            }
        }
        setLoading(false)
    }

    useEffect(() => {
        getData()
    },[])

    const addData = () => {
        const newState = [...state]
        newState.push({
            description: '',
            status: true
        })
        setState(newState)
    }

    const handleChange = (value: string|boolean, input: string, index: number) => {
        const newState = [...state]
        newState[index][input] = value
        setState(newState);
    };

    async function onSubmit(dataClient: any, index: number) {
        const {data, isError} = dataClient.id ?
            await documentTypeApi.update(dataClient.id, dataClient)
            : await documentTypeApi.create(dataClient);

        if (isError){
            if (typeof data === 'object') {
                Object.keys(data).forEach(function(key, index) {
                    Alert.error(data[key][0]);
                });
                return;
            }
            return Alert.error(data.message? data.message : data);
        }
        if(!dataClient.id){
            const newState = [...state]
            newState[index] = data
            setState(newState);
        }
    }

    async function onDelete(dataClient: any, index: number) {
        const newState = [...state]
        if (newState.length === 1){
            Alert.error("Deve haver pelo menos uma informação!");
            return
        }
        const confirm = await Alert.confirm(
            'Tem certeza que deseja excluir?',
            `Excluir esse dado?`
        );
        if (! confirm) return;
        if (dataClient.id){
            const {data, isError} = await documentTypeApi.destroy(dataClient.id)
            if (isError){
                if (typeof data === 'object') {
                    Object.keys(data).forEach(function(key, index) {
                        Alert.error(data[key][0]);
                    });
                    return;
                }

                return Alert.error(data.message? data.message : data);
            }
        }


        setState(newState.filter((value, indexFilter) => indexFilter != index));
    }

    const statusOptions = [
        { value: 1, label: 'Ativo' },
        { value: 0, label: 'Inativo' },
    ];

    return(
        <>
            {
                loading ?
                    <span className="flex items-center justify-center h-20 p-5">
                      <svg className="animate-spin -ml-1 mr-3 h-[30px] w-[30px] text-system-primary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </span>
                    :
                    <div className={'p-4'} >
                        {
                            state.map((data: any, index: number) => {

                                return(
                                    <div className="grid grid-cols-12 gap-5 mt-4">
                                        <Input
                                            disabled={!permissionEdit}
                                            OnBlur={() => onSubmit(data, index)}
                                            setValue={(v) => handleChange(v, 'description', index)}
                                            value={data.description}
                                            large
                                            className={'md:col-span-7 xs:col-span-12'}
                                            label={'Descrição'}
                                        />
                                        <div className={'h-full gap-3 flex md:col-span-3 xs:col-span-3 items-center mt-2'}>
                                            <button disabled={!permissionEdit} onClick={addData} className={'bg-system-blue-dark  md:w-[29px] md:h-[29px] rounded-full flex justify-center items-center hover:bg-system-primary hover:text-white'}>
                                                <BsPlus/>
                                            </button>
                                            <button disabled={!permissionEdit} onClick={() => onDelete(data, index)} className={'bg-system-blue-dark  md:w-[29px] md:h-[29px] rounded-full flex justify-center items-center hover:border-system-red hover:bg-system-red hover:text-white '}>
                                                <IoIosClose/>
                                            </button>
                                            <InputSelect
                                                disabled={!permissionEdit}
                                                key={(Math.random() + 1).toString(36).substring(7)}
                                                selected={data.status ? 1 : 0}
                                                label={'Status'}
                                                className={'md:col-span-2 xs:col-span-12 mt-[-27px]'}
                                                options={statusOptions}
                                                onChangeSelect={(e: any) => handleChange(e.value, 'status', index)}
                                                placeholder={'Status'}
                                            />

                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
            }
        </>

    )
}