import {useParams} from "react-router";
import React, {useEffect, useState} from "react";
import ListClean from "../../../components/layout/LayoutListClean";
import Input from "../../../components/inputs/Input";
import CardPosts from "../../../components/CardPost";
import propertyApi from "../../../services/propertyApi";
import postApi from "../../../services/postApi";
import Alert from "../../../helpers/Alert";
import useModal from "../../../hooks/useModal";
import Modal from "../../../components/modal/Modal";
import PostComponent from "../../../components/PostComponent";
import noImage from "../../../assets/img/noImage.png";
import Str from "../../../helpers/Str";
import Carousel from "react-multi-carousel";
import Table from "../../../components/Table";
import Storage from "../../../helpers/Storage";

export default function ListPostsProperties() {
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [property, setProperty]: any = useState([]);
    const [dateConsult, setDateConsult] = useState('');
    const [postEdit, setPostEdit] = useState({});
    const { modalProps, open, close } = useModal();
    const [dataModal, setDataModal]: any = useState();
    const [modelSelect, setModalSelect] = useState('edit-post')
    const [postLikesData, setPostLikesData] = useState([]);
    const { modalProps: modalEditProps, open: openEditModal, close: closeEditModal } = useModal();
    const { modalProps: modalLikeProps, open: openLikeModal, close: closeLikeModal } = useModal();
    const { modalProps: modalImageProps, open: openImageModal, close: closeImageModal } = useModal();

    useEffect(() => {
        getPostProperty()
    }, [])

    const getPostProperty = async (date?:string) => {
        setLoading(true)
        // @ts-ignore
        const {data, isError} = await propertyApi.getPostsProperty(params.id.toString(), date);
        if (!isError){
            if (data.posts){
                data.posts = Object.keys(data.posts).map((key) => data.posts[key]);
            }
            setProperty(data)
        }
        setLoading(false)
    }

    const onDeletePost = async (id: number | string) => {
        const confirm = await Alert.confirm(
            'Tem certeza que deseja excluir?',
            `Excluir esse post?`
        );
        if (! confirm) return;
        setLoading(true)
        const {data, isError} = await postApi.destroy(id as string);
        if(!isError){
            Alert.success('Deletado com sucesso')
            await getPostProperty()
        }
    }

    const onChangeDate = (value: string) => {
        setDateConsult(value);
        getPostProperty(value)
    }

    const onEditPost = (post: any) => {
        setModalSelect('edit-post')
        setPostEdit(post)
        openEditModal();
    }

    const onLikePostCount = async (post: any) => {
        const { data, isError } = await postApi.getPostLikes(post);
        if (!isError) {
            setPostLikesData(data);
            openLikeModal();
        } else {
            Alert.error('Erro ao carregar os likes');
        }
    };

    const closeModalEdit = () => {
        getPostProperty(dateConsult)
        closeEditModal();
    }

    const columns = [
        { label: "Cliente", index: "user_name", type: 'string' },
        { label: "Data/Hora", index: "created_at", type: 'dateT' },
    ];

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    function openModalImages(data: any){
        //setModalSelect('modal-images')
        setDataModal(data);
        openImageModal()
    }

    function closeModalImages(data: any){
        closeImageModal()
    }

    const permissions: any = Storage.getUserPermissions();
    const editValidation = permissions?.includes('property.edit');

    return(
        <ListClean
            client={false}
            title={'Atualizações do imóvel'}
            urlBack={`/propriedades/formulario/${params.id}`}
            input={<Input value={dateConsult} setValue={(v) => onChangeDate(v)} className={'w-[150px]  mt-[-15px] dark'}  type={'date'} />}
            descriptionProperty={property.description}
        >
            <div>
                {
                    loading?
                        <span className="flex items-center justify-center h-7">
                            <svg className="animate-spin -ml-1 mr-3 h-[20px] w-[20px] text-border" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </span>
                        :
                        <div className={'flex flex-col gap-5'}>
                            {
                                property.posts.length ?
                                    property.posts.map((post: any) => {
                                        if (post.files){
                                            return(
                                                <CardPosts
                                                    post={post}
                                                    id={post.id}
                                                    description={post.description}
                                                    images={post.files.filter((file: any) => file.type == 'image')}
                                                    documents={post.files.filter((file: any) => file.type == 'file')}
                                                    dataCreation={post.created_at}
                                                    userCreate={post.user_create}
                                                    onDeletePost={onDeletePost}
                                                    onEditPost={onEditPost}
                                                    onClickImage={openModalImages}
                                                    onLikePostCount={onLikePostCount}
                                                    permissionEdit={editValidation}
                                                />
                                            )
                                        }else{
                                            return(
                                                <CardPosts
                                                    post={post}
                                                    id={post.id}
                                                    description={post.description}
                                                    images={[]}
                                                    documents={post.archives}
                                                    dataCreation={post.created_at}
                                                    userCreate={post.user_create}
                                                    onClickImage={openModalImages}
                                                    permissionEdit={editValidation}
                                                />
                                            )
                                        }
                                    })
                                    :
                                    ''
                            }
                        </div>

                }

            </div>
            <Modal
                size={'mdm'}
                title={'Editar postagem de atualização'}
                footer={false}
                {...modalEditProps}
            >
                <PostComponent
                    idProperty={parseInt(params.id as string)}
                    closeModal={closeModalEdit}
                    post={postEdit}
                />
            </Modal>
            <Modal
                size={'mdm'}
                title={'Exibição dos likes da Postagem'}
                footer={false}
                {...modalLikeProps}
            >
                <Table
                    columns={columns}
                    data={{ data: postLikesData }}
                    loading={false}
                    onClick={() => {}}
                    onPageChange={() => {}}
                    onPerPageChange={() => {}}
                    perPage={10}
                />
            </Modal>

            <Modal
                size={'mdm'}
                footer={false}
                {...modalImageProps}
            >
                {
                    dataModal ?
                        <div className={'bg-[#13131A] rounded-xl'}>
                            <div className="flex items-center justify-between p-4 ">
                                <div>
                                    <div className={'flex gap-3 items-center'}>
                                        <div>
                                            <img className={'w-[50px] h-[50px] rounded-full '} src={dataModal.photo_path ? dataModal.photo_path : noImage}/>
                                        </div>
                                        <div >
                                            <div className={'text-[#FAFAFB] text-[20px]'}>
                                                {dataModal.user_create.name}
                                            </div>
                                            <div className={'text-[#696974] text-[14px]'}>
                                                {Str.convertDateStringComplet(dataModal.created_at)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    onClick={closeImageModal}
                                    type="button"
                                    className="transition text-system-primary rounded-full p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-light focus:outline-none focus:text-gray-600"
                                >
                                    <span className="sr-only">Close menu</span>
                                    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                            <div>
                                <Carousel
                                    responsive={responsive}
                                    infinite={dataModal.files.length > 1}
                                    autoPlay
                                    transitionDuration={2000}
                                    showDots={true}
                                >
                                    {
                                        dataModal.files.map((file: any) => {
                                            if(file.type == 'image'){
                                                return(
                                                    <div className={'w-full flex p-3 gap-2 justify-center items-center '}>
                                                        <img
                                                            className={'h-[450px] '}
                                                            src={file.url_path}
                                                        />
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </Carousel>
                            </div>
                            <div className={'text-[16px] text-[#B5B5BE] p-4'}>
                                {dataModal.description}
                            </div>
                        </div>
                        :
                        ''
                }

            </Modal>
        </ListClean>
    )
}