import { QueryParams } from '../types';
import api, {getAll, create, update, getOne, deleteOne, listAll} from './api';

const endpoint = '/post';

export default {
    all: async (params?: QueryParams) => getAll<any>({endpoint, params}),
    listAll: async () => listAll<any>({endpoint}),
    one: async (id: string) => getOne<any>({endpoint, id}),
    destroy: async (id: string) => deleteOne({endpoint, id}),
    create: async (formData: Partial<any>) => create<any>({endpoint, formData}),
    update: async (id: string, formData: Partial<any>) => update<any>({ endpoint, formData, id }),
    async getPostsUserDate(date: string){
        try {
            const {data} = await api.get(endpoint+'/date/'+date);
            return {data, isError: false};
        } catch (err: any) {
            return {
                data: err?.response?.data ?? {message: 'Falha ao processar requisição'},
                isError: true
            };
        }
    },
    async getPostsUser(date?: string | number | undefined | null, page?: string | number, typeProperty?: number | null | undefined){
        try {
            const url = `${endpoint}/user/data?page=${page ? page : 1}${date ? '&date='+date : ''}${typeProperty ? '&type_property='+typeProperty : ''}`
            const {data} = await api.get(url);
            return {data, isError: false};
        } catch (err: any) {
            return {
                data: err?.response?.data ?? {message: 'Falha ao processar requisição'},
                isError: true
            };
        }
    },
    async likePost(id: string) {
        try {
            const { data } = await api.post(`${endpoint}/${id}/like`);
            return { data, isError: false };
        } catch (err: any) {
            return {
                data: err?.response?.data ?? { message: 'Falha ao dar like' },
                isError: true
            };
        }
    },
    async unlikePost(id: string) {
        try {
            const { data } = await api.post(`${endpoint}/${id}/unlike`);
            return { data, isError: false };
        } catch (err: any) {
            return {
                data: err?.response?.data ?? { message: 'Falha ao retirar like' },
                isError: true
            };
        }
    },
    async getPostLikes(id: string){
        try {
            const {data} = await api.get(`${endpoint}/${id}/likes`);
            return {data, isError: false};
        } catch (err: any) {
            return {
                data: err?.response?.data ?? {message: 'Falha ao processar requisição'},
                isError: true
            };
        }
    },
}