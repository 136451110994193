
import {
    Settings
} from "../../../components/Icons";
import FormLayout from "../../../components/layout/FormLayout";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import Input from "../../../components/inputs/Input";
import InputTextArea from "../../../components/inputs/InputTextArea";
import InputRating from "../../../components/inputs/InputRating";
import Str from "../../../helpers/Str";
import useModal from "../../../hooks/useModal";
import Modal from "../../../components/modal/Modal";
import Alert from "../../../helpers/Alert";
import {useNavigate} from "react-router-dom";
import contactApi from "../../../services/contactApi";

interface Status {
    ABERTO: { value: string; description: string; };
    EM_ANDAMENTO: { value: string; description: string; };
    AGUARDANDO: { value: string; description: string; };
    FINALIZADO: { value: string; description: string; };
}

const STATUS: Status = {
    ABERTO: { value: '1', description: 'Aberto' },
    EM_ANDAMENTO: { value: '2', description: 'Em Andamento' },
    AGUARDANDO: { value: '3', description: 'Aguardando' },
    FINALIZADO: { value: '4', description: 'Finalizado' },
};

type StatusKeys = keyof Status;

interface FormState {
    id: string;
    subject: string;
    contact_type: string;
    status: string;
    created_at: string;
    finalized_at: string;
    message: string;
    response_message: string;
}

export default function FormAdminMessage() {
    const defaltValues = {
        id: '',
        subject: '',
        contact_type: '',
        status: '',
        created_at: '',
        finalized_at: '',
        message: '',
        response_message: '',
    }
    const navigate = useNavigate();
    const [state, setState]: any = useState(defaltValues);
    const params = useParams();
    const [loading, setLoading] = useState(true);    

    const { modalProps, open, close } = useModal();

    const openModalRating = async () => {
        setLoading(true);

        // @ts-ignore
        const {data, isError} = await contactApi.one(params.id.toString());

        setLoading(false);
        open()
    }

    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };

    const getState = async () => {
        // @ts-ignore
        const {data, isError} = await contactApi.one(params.id.toString());

        setState({
            id: data.id,
            subject: data.subject,
            contact_type: data.contact_type,
            contact_type_description: data.contact_type_description,
            status: data.status,
            status_description: data.status_description,
            created_at: Str.convertDateCreatedAt(data.created_at),
            finalized_at: Str.convertDateCreatedAt(data.finalized_at),
            message: data.message,
            response_message: data.response_message,
            rating: data.rating,
        })
        setLoading(false)
    }

    const updateStatus = async (newStatus: StatusKeys) => {
        if (state.id) {
            const confirm = await Alert.confirm(
                `Tem certeza que deseja alterar o status para "${STATUS[newStatus].description}"?`,
                `Alterar Status`
            );
            if (!confirm) return;
    
            const updatedData = {
                status: STATUS[newStatus].value // Use a propriedade value do status
            };
    
            const { data, isError } = await contactApi.update(state.id, updatedData);
    
            if (!isError) {
                setState((prevState: FormState) => ({
                    ...prevState,
                    status: STATUS[newStatus].value // Atualiza com o valor correspondente
                }));
                getState();
                Alert.success(`Status alterado para "${STATUS[newStatus].description}" com sucesso!`);
            } else {
                Alert.error('Erro ao alterar o status.');
            }
        } else {
            Alert.error('Necessário selecionar uma mensagem.');
        }
    };    

    useEffect(() => {
        if (params.id){
            getState()
        }else{
            setLoading(false)
        }
    },[])

    return(
        <>
            <FormLayout
                title={'Mensagem'}
                urlReturn={'/mensagens'}
                state={state}
                setState={setState}
                showDeleteButton={false}
                showNewDataButton={false}
                apiData={contactApi}
                defaltValues={defaltValues}
                sideMenu={
                    <>
                        <button disabled={!state.id || state.status == STATUS.FINALIZADO.value}  type={'button'} onClick={() => updateStatus('EM_ANDAMENTO')} className={'flex gap-2 items-center text-[14px] hover:text-system-primary'}>
                            <Settings className={'text-[22px]'}/>Dar andamento
                        </button>

                        <button disabled={!state.id || state.status == STATUS.FINALIZADO.value} onClick={() => updateStatus('AGUARDANDO')} className={'flex gap-2 items-center text-[14px] hover:text-system-primary'}>
                            <Settings className={'text-[22px]'}/>Pausar
                        </button>

                        <button disabled={!state.id || state.status == STATUS.FINALIZADO.value} onClick={() => updateStatus('AGUARDANDO')} className={'flex gap-2 items-center text-[14px] hover:text-system-primary'}>
                            <Settings className={'text-[22px]'}/>Parar
                        </button>

                        <button disabled={!state.id || state.status == STATUS.FINALIZADO.value} onClick={() => updateStatus('FINALIZADO')} className={'flex gap-2 items-center text-[14px] hover:text-system-primary'}>
                            <Settings className={'text-[22px]'}/>Finalizar
                        </button>

                        <button disabled={!state.id || !state.rating} onClick={openModalRating} className={'flex gap-2 items-center text-[14px] hover:text-system-primary'}>
                            <Settings className={'text-[22px]'}/>Ver avaliação
                        </button>
                    </>
                }
                sideMenuHeight="h-[200px]"
                sideMenuWidth="md:w-[300px]"
            >
                {
                    loading ?
                        <span className="flex items-center justify-center h-7">
                            <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </span>
                        :
                        <>
                            <div className={'flex  w-full '}>
                                <div className={'sm:w-full grid p-3'}>
                                    <div className="mt-3 grid grid-cols-12 gap-3">
                                        <Input
                                            label={'Assunto'}
                                            disabled
                                            value={state.subject}
                                            className={'md:col-span-12 xs:col-span-12 content-center'}
                                        />
                                    </div>
                                    <div className="mt-3 grid grid-cols-12 gap-3">
                                        <Input
                                            label={'Tipo de contato'}
                                            disabled
                                            value={state.contact_type_description}
                                            className={'md:col-span-3 xs:col-span-12 content-center'}
                                        />
                                        <Input
                                            label={'Situação'}
                                            disabled
                                            value={state.status_description}
                                            className={'md:col-span-3 xs:col-span-12 content-center'}
                                        />
                                        <Input
                                            label={'Abertura'}
                                            disabled
                                            value={state.created_at}
                                            className={'md:col-span-3 xs:col-span-12 content-center'}
                                        />
                                        <Input
                                            label={'Conclusão'}
                                            disabled
                                            value={state.finalized_at}
                                            className={'md:col-span-3 xs:col-span-12 content-center'}
                                        />
                                    </div>
                                    <div className="mt-3 grid grid-cols-12 gap-3">
                                        <InputTextArea
                                            label={'Mensagem'}
                                            disabled
                                            value={state.message}
                                            className={'md:col-span-12 xs:col-span-12 content-center'}
                                            rows={4}
                                        />
                                    </div>
                                    <div className="mt-3 grid grid-cols-12 gap-3">
                                        <InputTextArea
                                            label={'Resposta'}
                                            value={state.response_message}
                                            setValue={(v) => handleChange(v, 'response_message')}
                                            className={'md:col-span-12 xs:col-span-12 content-center'}
                                            rows={4}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                }
            </FormLayout>
            <Modal
                size={'mdm'}
                title={"Avaliação do Atendimento"}
                footer={false}
                {...modalProps}
            >
                {
                    loading ?
                        <span className="flex items-center justify-center h-7">
                            <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </span>
                        :
                        <>
                            <div className={'flex w-full justify-center'}>
                                <div className={'col-span-4 flex items-center md:p-3 md:ml-3 md:gap-2'}>
                                    <InputRating value={state.rating} setValue={(value) => setState({...state, rating: value})} />
                                </div>

                            </div>
                            <div className={'sm:w-full grid p-3'}>
                                <div className="grid grid-cols-12 gap-3">
                                </div>
                            </div>
                        </>
                }
            </Modal>
        </>
    )
}