import PageContainer from "../../components/container/PageContainer";
import LayoutDefault from "../../components/layout/LayoutDefault";
import {Tabs} from "../../components/Tabs";
import {ConfigContent} from "./Config";
import {AccessProfile} from "./AccessProfile";
import {PropertyType} from "./PropertyType";
import {DocumentType} from "./DocumentType";
import {WorkStatus} from "./WorkStatus";
import Storage from "../../helpers/Storage";

export default function Config() {
    const permissions: any = Storage.getUserPermissions();
    const editValidation = permissions?.includes('config.edit');

    const tabsData = [
        {
            label: 'Configurações',
            content:<ConfigContent permissionEdit={editValidation}/>,
        },
        {
            label: 'Perfil de Acessos',
            content: <AccessProfile permissionEdit={editValidation}/> ,
        },
        {
            label: 'Tipo de Imóvel',
            content: <PropertyType permissionEdit={editValidation}/>,
        },
        {
            label: 'Tipo de Documento',
            content: <DocumentType permissionEdit={editValidation}/>,
        },
        {
            label: 'Status da Obra',
            content: <WorkStatus permissionEdit={editValidation}/>,
        },
    ];
    return(
        <LayoutDefault title={'Configurações'}>
            <Tabs tabsData={tabsData}/>
        </LayoutDefault>
    )
}