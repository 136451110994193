import { QueryParams } from '../types';
import api, {getAll, create, update, getOne, deleteOne, listAll} from './api';

const endpoint = '/dashboard';

export default {
    async getDataDashboard(consult: string, params: any){
        try {
            const page    = params?.page    ? '&page='    + params?.page    : '&page=1';
            const perPage = params?.perPage ? '&perpage=' + params?.perPage : '';
            const url = `${endpoint}?consult=${consult}${page}${perPage}&order=id,desc`
            const {data} = await api.get(url);
            return {data, isError: false};
        } catch (err: any) {
            return {
                data: err?.response?.data ?? {message: 'Falha ao processar requisição'},
                isError: true
            };
        }
    },
    async getDataDashboardQuantity(){
        try {
            const {data} = await api.get(endpoint+'/quantity');
            return {data, isError: false};
        } catch (err: any) {
            return {
                data: err?.response?.data ?? {message: 'Falha ao processar requisição'},
                isError: true
            };
        }
    },
}