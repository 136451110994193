import Str from '../../../helpers/Str';

export interface InputProps {
    className?: string;
    placeholder?: string;
    label?: string;
    error?: string;
    value?: string | number;
    setValue?(value: string): void;
    large?: boolean;
    disabled?: boolean;
    hideLabel?: boolean;
    name?: string;
    key?: string;
    required?: boolean;
    OnBlur?: any;
    rows?: number;
}

export default function InputTextArea({
  className,
  value,
  label,
  error,
  setValue,
  placeholder,
  large,
  hideLabel,
  name,
  key,
  required,
  OnBlur,
  rows,
  ...rest
}: InputProps) {

    function handleOnChange(event: any): void {
        if (! setValue) return;

        let value = event.target.value as string;

        setValue(value);
    }

    return (
        <label className={Str.tw('w-full', className)}>
            {!hideLabel && <span>{label} {(label && required) ? <span className='text-red-600'>*</span> : ''}</span>}
            {
                <textarea
                    required={required}
                    key={key}
                    {...rest}
                    name={name}
                    rows={rows}
                    placeholder={placeholder}
                    value={value}
                    onChange={handleOnChange}
                    onBlur={OnBlur}
                    className={Str.tw(
                        large ? 'py-3' : 'py-2',
                        'text-[#939396] placeholder-[#939396]',
                        'bg-system-blue-dark border border-[#292932] rounded-md outline-none px-4 w-full',
                        'focus:border-border transition'
                    )}
                />
            }
            {!!error?.length && <span className="text-red-600 ">{error}</span>}
        </label>
    );
}