import PageContainer from "../../../components/container/PageContainerClient";
import React, {useEffect, useState} from "react";
import useTable from "../../../hooks/useTable";
import Alert from "../../../helpers/Alert";
import contactApi from "../../../services/contactApi";
import Table from "../../../components/Table";
import {PaginatedData} from "../../../types";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router";

export default function ClientMessage() {
    const [loading, setLoading] = useState(true);
    const [loadingTable, setloadingTable] = useState(false);
    const [titleTable, setTitleTable] = useState('Mensagens');

    const navigate = useNavigate();

    const { tableProps, loadData } = useTable({
        denyInitialLoad: true,
        dataLoader: async () => {
            setloadingTable(true);
            const { data, isError } = await contactApi.getContactsUser();
            if (isError) {
                Alert.error(data.message || data.error || 'Falha ao carregar dados.');
                setloadingTable(false);
                return;
            }
            setLoading(false);
            setloadingTable(false);
            return data as any;
        }
    });

    useEffect(() => {
        loadData();
    }, []);

    const columns = [
        { label: "Assunto", index: "subject", type: 'string' },
        { label: "Data", index: "created_at", type: 'dateT' },
        { label: "Finalizado", index: "finalized_at", type: 'dateT' },
    ];

    const goToMessage = (messageData: any) => {
        navigate(`/cliente/mensagens/${messageData.id}`);
    };

    return(
        <PageContainer>
            <div className={'flex flex-wrap gap-5 w-full md:justify-center'}>
                {
                    loading ?
                        <span className="flex items-center justify-center h-7">
                            <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-border" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </span>
                        :
                        <div className={'flex flex-col gap-3 w-full'}>

                            <div className={'border border-system-gray-gray80 rounded-xl mb-[90px] bg-system-blue-medium'}>
                                <div className={'p-5 text-system-primary text-[20px] font-semibold border-b border-system-gray-gray80'}>
                                    {titleTable}
                                </div>
                                <div className="table_component " role="region">
                                {
                                    loadingTable ?
                                        <span className="flex items-center justify-center h-7">
                                            <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-border" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                        </span>
                                        :

                                        <Table
                                            onClick={goToMessage}
                                            {...tableProps}
                                            data={tableProps.data}
                                            columns={columns || []}
                                        />
                                }

                            </div>
                            </div>
                        </div>

                }
            </div>
        </PageContainer>
    )
}