import React, { CSSProperties, Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button from "../../button/Button";

export interface TriggerParams {
  open: () => void;
  close: () => void;
  toggle: () => void;
}

export interface ModalProps {
  // eslint-disable-next-line no-unused-vars
  trigger?: React.ReactNode | ((params: TriggerParams) => React.ReactNode);
  children?: React.ReactNode | ((params: TriggerParams) => React.ReactNode);
  onOpen?: () => void;
  size?: 'auto' | 'max-content' | 'sm' | 'md' | 'lg' | 'xl' | 'lm' | 'mdm' | 'mds';
  style?: CSSProperties;
  title?: string;
  className?: string;
  innerRef ?: any;
  footer ?: boolean;
  onClick ?: any;
  btnOnclickName ?: string;
  btnRowReverse ?: boolean;
}

export default function Modal({
  trigger,
  onOpen,
  title,
  size,
  style,
  innerRef,
  className,
  children,
  footer,
  onClick,
  btnOnclickName,
  btnRowReverse
}: ModalProps) {
  const [isOpen, setIsOpen] = useState(false);

  function handleSizeClass() {
    if (size === 'sm') {
      return 'w-full max-w-[95vw] md:max-w-sm';
    }
    
    if (size === 'md') {
      return 'w-full max-w-[95vw] md:max-w-md';
    }

    if (size === 'mds') {
      return 'w-full max-w-[95vw] md:max-w-[33rem]';
    }

    if (size === 'mdm') {
      return 'w-full max-w-[95vw] md:max-w-[50rem]';
    }

    if (size === 'lg') {
      return 'w-full max-w-[95vw] md:max-w-lg';
    }

    if (size === 'xl') {
      return 'w-full max-w-[95vw] md:max-w-xl';
    }

    if (size === 'lm') {
      return 'w-full max-w-[95vw] md:max-w-[60rem]';
    }

    if (size === 'max-content') {
      return 'w-full max-w-[95vw] md:max-w-[max-content]';
    }

    return 'w-full max-w-[95vw] md:max-w-[90vw]';
  }

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
    if (onOpen) {
      onOpen();
    }
  }

  return (
    <>
      {innerRef && <span onClick={openModal} hidden ref={innerRef}></span>}
      {!trigger ? (<></>) : typeof trigger === 'function' ? trigger({
        toggle: toggleModal,
        close: closeModal,
        open: openModal,
      }) :  (
        <span onClick={openModal} className={className}>
          {trigger}
        </span>
      )}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[999] scrollbar" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto scrollbar">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel style={style} className={`${handleSizeClass()}  bg-system-blue-dark border border-border transform {/*overflow-hidden*/} rounded-xl text-[#b5b5be]  text-left align-middle shadow-xl  transition-all`}>
                  {
                    title ?
                        <header className="flex items-center justify-between p-4 border-b border-system-gray-gray80">
                          <Dialog.Title
                              as="h3"
                              className="text-lg font-medium leading-6"
                          >
                            {title ?? ''}
                          </Dialog.Title>

                          <button
                              onClick={closeModal}
                              type="button"
                              className="transition text-system-primary rounded-full p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-light focus:outline-none focus:text-gray-600"
                          >
                            <span className="sr-only">Close menu</span>
                            <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </button>
                        </header>
                        : ''
                  }


                  <main className={`bg-[#1C1C24] ${title ? 'rounded-b-xl' : 'rounded-xl'}`} style={{ height: '93%' }}>
                    {typeof children !== 'function' ? children : children({
                      toggle: toggleModal,
                      close: closeModal,
                      open: openModal,
                    })}
                  </main>
                  {
                    footer ?
                        <footer className={`p-4 border-t border-system-gray-gray80 flex ${btnRowReverse ? 'flex-row-reverse' : ''} justify-between`}>

                          {
                            onClick ?
                                <Button onClick={onClick} className={'w-[180px] h-[36px]'}>

                                  {
                                    btnOnclickName ? btnOnclickName : 'Salvar'
                                  }
                                </Button>
                                :
                                ''
                          }


                          <Button onClick={closeModal} className={'w-[180px] h-[36px] border border-border bg-system-blue-dark text-system-primary'}>Cancelar</Button>
                        </footer>
                        :
                        ''
                  }

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
