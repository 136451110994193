import {ITableBody} from "../../../types";
import Str from "../../../helpers/Str";
import {useState} from "react";

export default function TableBody({columns, tableData, onClick}: ITableBody) {
    const [stepWorck, setStepWorck]: any = useState({
        earthworks_support: 5,
        gray_work: 60,
        finish: 35,
    });
    return(
        <tbody>
            {
                tableData && tableData.map((record: any, index) => {
                    return(
                        <tr key={index} onClick={() => onClick(record)} className={`hover:bg-[#6B5324] odd:bg-[#1C1C24]  cursor-pointer border-t border-system-gray-gray80`}>
                            {
                                columns && columns.map((column: any) => {

                                    if (column.type === 'status') {
                                        return (
                                            <td className="py-5 px-2">
                                                {record[column.index] ? 'Ativo' : 'Inativo'}
                                            </td>
                                        )
                                    }

                                    if (column.type === 'phone') {
                                        return (
                                            <td className="py-5 px-2">
                                                {Str.addPhoneMask(record[column.index])}
                                            </td>
                                        )
                                    }

                                    if (column.type === 'dateT') {
                                        return (
                                            <td className="py-5 px-2">
                                                {Str.convertDateCreatedAt(record[column.index])}
                                            </td>
                                        )
                                    }

                                    if (column.type === 'address') {
                                        return (
                                            <td className="py-5 px-2">
                                                {record['address']}
                                                {record['number'] ? ', Numero: '+record['number'] : ''}
                                                {record['block'] ? ', Qd: '+record['block'] : ''}
                                                {record['lot'] ? ', Lt: '+record['lot'] : ''}
                                            </td>
                                        )
                                    }

                                    if (column.type === 'array') {

                                        if (record[column.index]){
                                            let names = '';
                                            record[column.index].map((array: any) => {
                                                if (names){
                                                    names += ', '+array['description']
                                                }else{
                                                    names = array['description']
                                                }
                                            })
                                            return (
                                                <td className="py-5 px-2">
                                                    {names}
                                                </td>
                                            )

                                        }
                                    }
                                    if (column.type === 'progress') {

                                        const calc_earthworks_support = (stepWorck['earthworks_support']/100) * record['earthworks_support'];
                                        const calc_gray_work = (stepWorck['gray_work']/100) * record['gray_work'];
                                        const calc_finish = (stepWorck['finish']/100) * record['finish'];

                                        const totalCalc = calc_earthworks_support + calc_gray_work + calc_finish

                                        //return totalCalc
                                        let collor = '#F94F4E'
                                        if (totalCalc >= 76 && totalCalc <= 100){
                                            collor = '#6DB44C';
                                        }else if (totalCalc >= 41 && totalCalc <= 75){
                                            collor = '#E0A422';
                                        }else if (totalCalc <= 40){
                                            collor = '#F94F4E';
                                        }

                                        return (
                                            <td className="py-5 px-2 flex items-center gap-1">
                                                <div className={`h-[8px] w-full bg-neutral-200 dark:bg-neutral-600 rounded-lg`}>
                                                    <div className={`h-[8px] rounded-lg `} style={{width: totalCalc+'%', backgroundColor:collor}}/>
                                                </div>
                                                {totalCalc+'%'}
                                            </td>
                                        )
                                    }



                                    const concat = column.index.split("+");
                                    if (concat.length > 1){

                                        let fieldsRealtionchipPrimary = concat[0].split(".")
                                        let fieldsRealtionchipSecondary = concat[1].split(".")

                                        if (fieldsRealtionchipPrimary.length > 1 && fieldsRealtionchipSecondary.length > 1){

                                            const firstDt = record[fieldsRealtionchipPrimary[0]][fieldsRealtionchipPrimary['1']];
                                            const lastDt = record[fieldsRealtionchipSecondary[0]][fieldsRealtionchipSecondary['1']]

                                            return (
                                                <td className="py-5 px-2">
                                                    {firstDt} {lastDt}
                                                </td>
                                            )
                                        }

                                        return (
                                            <td className="py-5 px-2">
                                                {record[concat[0]]} {record[concat[1]]}
                                            </td>
                                        )
                                    }

                                    let relations = column.index.split(".");
                                    if (relations.length > 1) {

                                        let our = relations[1].split(":")

                                        const fildOne = relations[0];
                                        const fildTwo = relations[1];
                                        const isArray = Array.isArray(record[fildOne])
                                        /*if (column.type === 'progress') {
                                            let collor = '#F94F4E'
                                            if (parseInt(record[fildOne][our[0]]) >= 76 && parseInt(record[fildOne][our[0]]) <= 100){
                                                collor = '#6DB44C';
                                            }else if (parseInt(record[fildOne][our[0]]) >= 41 && parseInt(record[fildOne][our[0]]) <= 75){
                                                collor = '#E0A422';
                                            }else if (parseInt(record[fildOne][our[0]]) <= 40){
                                                collor = '#F94F4E';
                                            }

                                            return (
                                                <td className="py-5 px-2 flex items-center gap-1">
                                                    <div className={`h-[8px] w-full bg-neutral-200 dark:bg-neutral-600 rounded-lg`}>
                                                        <div className={`h-[8px] rounded-lg `} style={{width: record[fildOne][our[0]]+'%', backgroundColor:collor}}/>
                                                    </div>
                                                    {record[fildOne][our[0]]+'%'}
                                                </td>
                                            )
                                        }*/
                                        if (record[fildOne]){
                                            return (
                                                <td className="py-5 px-2">
                                                    {record[fildOne][our[0]]}
                                                </td>
                                            )
                                        }
                                    }


                                    return(
                                        <td  className="py-5 px-2">
                                            {record[column.index]}
                                        </td>
                                    )
                                })
                            }

                        </tr>
                    )
                })
            }
        </tbody>
    )
}