import PageContainer from "../../components/container/PageContainer";
import React, {useEffect, useState} from "react";
import CardNotifications from "../../components/CardNotification";
import notificationApi from "../../services/notificationApi";

export default function Notification() {
    const [loading, setLoading] = useState(true);

    const [notifications, setNotifications]: any = useState([]);

    const [totalPage, setTotalPage] = useState();
    const[pageTo, setPageTo] = useState();

    const getNotificationUser = async () => {
        const {data, isError} = await notificationApi.listAll();
            
        if (!isError) {
            if (data) {
                setNotifications(data);
            }
        }
        setLoading(false)
    }

    useEffect(() => {
        getNotificationUser()
    }, []);


    return(
        <PageContainer>
            {
                loading ?
                    <span className="flex items-center justify-center h-7">
                        <svg className="animate-spin -ml-1 mr-3 h-[20px] w-[20px] text-border" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </span>
                    :
                    <div className={'flex md:flex-row xs:flex-col-reverse justify-center gap-4 md:mb-[4rem]'}>
                        <div className={'flex flex-col md:w-[700px] gap-3 mb-[20px]'}>
                            {
                                notifications.length ?
                                    <>
                                        {
                                            notifications.map((notification: any) => {
                                                return(
                                                    <CardNotifications
                                                        id={notification.id}
                                                        description={notification.description}
                                                        dataCreation={notification.created_at}
                                                    />
                                                )
                                            })
                                        }
                                    </>
                                    :
                                    <div className={'flex border border-[#13131A] border-border items-center justify-center text-[20px] w-full p-3 h-[73px] rounded-xl bg-[#13131A]'}>
                                        Nenhuma atualização disponível
                                    </div>
                            }
                        </div>
                    </div>
            }
        </PageContainer>
    )
}