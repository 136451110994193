import PageContainer from "../../components/container/PageContainer";
import ListPageLayout from "../../components/layout/ListPageLayout";
import userApi from "../../services/userApi";

export default function Users() {
    return(
        <ListPageLayout
            title={'Cadastro de Usuários'}
            urlCreate={'/usuarios/formulario'}
            queries={{['where']: 'type,=,admin' }}
            collunSearch={'name'}
            permission={'users.edit'}
            table={
                {
                    loader: userApi.all,
                    columns: [
                        { label: "Nome", index: "name", type: 'string'},
                        { label: "E-mail", index: "email", type: 'string'},
                        { label: "Status", index: "status", type: 'status'},
                    ]
                }
            }
        />
    )
}