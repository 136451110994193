import {IoIosClose} from "react-icons/io";
import noImage from "../../assets/img/noImage.png";
import {AddCircleHalfDot, Delete, Doc, Download, Pdf} from "../Icons";
import Str from "../../helpers/Str";
import DocumentButton from "../button/DocumentButton";
import React from "react";
interface ICardProperty {
    property: any;
    onDelete?: any;
}

export default function CardProperty({property, onDelete}: ICardProperty){
    return(
        <div className={'flex flex-col bg-[#1C1C24] hover:text-system-primary hover:border hover:border-border items-center rounded-md  md:w-[625px]  text-[#B5B5BE] p-4 '}>
            <div className={`flex ${onDelete ? 'justify-between' : 'gap-4'} w-full`}>
                <div>
                    <img className={'w-[131px] h-[131px] rounded-md border-2 border-border'} src={property.photo_path ? property.photo_path : noImage}/>
                </div>
                <div className={'flex flex-col gap-2'}>
                    <div className={'text-[17px] text-border text-[#B5B5BE]'}>
                        {property.description}
                    </div>
                    <div className={'text-[14px] text-[#B5B5BE]'}>
                        {
                            `${property.address}, Nº ${property.number}, Qd: ${property.block}, Lote: ${property.lot} - ${property.neighborhood}`
                        }
                        <p/>
                        {
                            `CEP: ${property.cep} - ${property.city} - ${property.state}`
                        }
                    </div>
                    <div className={'text-[14px] text-[#B5B5BE]'}>
                        <span className={'text-border'}>Situação:</span> {property.status_construction.description}
                    </div>
                </div>
                {
                    onDelete ?
                        <div className={' mt-[-20px]'}  >
                            <button onClick={() => onDelete(property)}>
                                <IoIosClose size={25}/>
                            </button>
                        </div>
                        :
                        <></>
                }

            </div>
            <div className={'flex flex-col w-full gap-3 mt-4'}>
                {
                    property.files?.length ?
                        property.files.map((fileData: any) => {
                            if (fileData.archives.length){
                               return fileData.archives.map((file: any) => {
                                    const icon  = (file.type === 'application/pdf' || file.type === 'pdf') ? <Pdf className={'text-border text-[35px]'}/> : <Doc className={'text-border text-[35px]'}/>
                                    return  (
                                        <div className={'flex gap-3'}>
                                            <div className={'flex w-[25rem] border border-[#13131A] hover:border-border items-center justify-between w-full p-3 h-[73px] rounded-xl bg-[#13131A]'}>
                                                <div className={'text-[#B5B5BE] flex gap-2 items-center'}>
                                                    {icon} <div className={'text-[14px]'}>{file.name ? file.name : file.original_name}</div>
                                                </div>
                                                <div className={'flex text-[#64646C] gap-4'}>
                                                    <div >
                                                        {Str.convertDateCreatedAt(file.created_at)}
                                                    </div>
                                                    {
                                                        file.original_name ?
                                                            <div>
                                                                <button type={'button'} onClick={()=> window.open(file.file_path, "_blank")} className={'hover:text-border'}>
                                                                    <Download/>
                                                                </button>
                                                            </div>
                                                            :
                                                            ''
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    )
                                })
                            }

                        }) : ''
                }
            </div>
        </div>
    )
}