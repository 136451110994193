import ImgFolder from "../../../assets/img/folder.png";
import React from "react";

interface IButtonFolder {
    description: string;
    onClick: () => void;
}

export default function ButtonFolder({description, onClick}: IButtonFolder) {
    return(
        <div className={'flex flex-col items-center hover:cursor-pointer'} onClick={() => onClick()}>
            <img className={'w-[100px] h-[100px]'} src={ImgFolder}/>
            <div className={'text-[14px]'}>{description}</div>
        </div>
    )
}