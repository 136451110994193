
import FormLayout from "../../../../components/layout/FormLayoutClient";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import Input from "../../../../components/inputs/Input";
import InputTextArea from "../../../../components/inputs/InputTextArea";
import InputRating from "../../../../components/inputs/InputRating";
import InputSelect from "../../../../components/inputSelect";
import Button from "../../../../components/button/Button";
import Str from "../../../../helpers/Str";
import useModal from "../../../../hooks/useModal";
import Modal from "../../../../components/modal/Modal";
import Alert from "../../../../helpers/Alert";
import { IoClose } from "react-icons/io5";
import DocumentButton from "../../../../components/button/DocumentButton";
import {CgSpinner} from "react-icons/cg";
import {useNavigate} from "react-router-dom";
import contactApi from "../../../../services/contactApi";
import Rating from "../../../../components/inputs/InputRating";

export default function FormClientMessage() {
    const defaltValues = {
        id: '',
        subject: '',
        contact_type: '',
        status: '',
        created_at: '',
        finalized_at: '',
        message: '',
        response_message: '',
        rating: null,
    }
    const navigate = useNavigate();
    const [state, setState]: any = useState(defaltValues);
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [isRated, setIsRated] = useState(false);
    const { modalProps, open, close } = useModal();

    const openModalRating = async () => {
        setLoading(true);

        // @ts-ignore
        const {data, isError} = await contactApi.one(params.id.toString());

        setLoading(false);
        open()
    }

    const onSaveRating = async () =>{
        try {
            if (!params.id) {
                return;
            }

            const contact = {
                rating: state.rating
            };
    
            const { data, isError } = await contactApi.update(params.id.toString(), contact);
            
            if (isError) {
                Alert.error('Erro ao avaliar');
                return;
            }
    
            setIsRated(true);
            getState()
            Alert.success('Avaliado com sucesso');
            close();
        } catch (error) {
            Alert.error('Erro ao avaliar');
        }
    }

    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };

    const getState = async () => {
        // @ts-ignore
        const {data, isError} = await contactApi.one(params.id.toString());

        setState({
            id: data.id,
            subject: data.subject,
            contact_type: data.contact_type_description,
            status: data.status_description,
            created_at: Str.convertDateCreatedAt(data.created_at),
            finalized_at: Str.convertDateCreatedAt(data.finalized_at),
            message: data.message,
            response_message: data.response_message,
            rating: data.rating,
        })
        setIsRated(data.rating !== null);
        setLoading(false)
    }

    useEffect(() => {
        if (params.id){
            getState()
        }else{
            setLoading(false)
        }
    },[])

    return(
        <>
            <FormLayout
                title={'Mensagens'}
                urlReturn={'/cliente/mensagens'}
                showButton={false}
                state={state}
                setState={setState}
                defaltValues={defaltValues}
            >
                {
                    loading ?
                        <span className="flex items-center justify-center h-7">
                            <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </span>
                        :
                        <>
                            <div className={'flex  w-full '}>
                                <div className={'sm:w-full grid p-3'}>
                                    <div className="mt-3 grid grid-cols-12 gap-3">
                                        <Input
                                            label={'Assunto'}
                                            disabled
                                            value={state.subject}
                                            className={'md:col-span-12 xs:col-span-12 content-center'}
                                        />
                                    </div>
                                    <div className="mt-3 grid grid-cols-12 gap-3">
                                        <Input
                                            label={'Tipo de contato'}
                                            disabled
                                            value={state.contact_type}
                                            className={'md:col-span-3 xs:col-span-12 content-center'}
                                        />
                                        <Input
                                            label={'Situação'}
                                            disabled
                                            value={state.status}
                                            className={'md:col-span-3 xs:col-span-12 content-center'}
                                        />
                                        <Input
                                            label={'Abertura'}
                                            disabled
                                            value={state.created_at}
                                            className={'md:col-span-3 xs:col-span-12 content-center'}
                                        />
                                        <Input
                                            label={'Conclusão'}
                                            disabled
                                            value={state.finalized_at}
                                            className={'md:col-span-3 xs:col-span-12 content-center'}
                                        />
                                    </div>
                                    <div className="mt-3 grid grid-cols-12 gap-3">
                                        <InputTextArea
                                            label={'Mensagem'}
                                            disabled
                                            value={state.message}
                                            className={'md:col-span-12 xs:col-span-12 content-center'}
                                            rows={4}
                                        />
                                    </div>
                                    <div className="mt-3 grid grid-cols-12 gap-3">
                                        <InputTextArea
                                            label={'Resposta'}
                                            disabled
                                            value={state.response_message}
                                            className={'md:col-span-12 xs:col-span-12 content-center'}
                                            rows={4}
                                        />
                                    </div>
                                </div>
                            </div>
                            {
                                state.finalized_at && state.status === "Finalizado" && (
                                    <div className={'sticky top-[95vh] flex gap-4 p-5 border-t border-system-gray-gray80 justify-end'}>
                                        <Button loading={loading} onClick={openModalRating} type={'button'} className={'w-[100px]'}>
                                            {isRated ? 'Avaliação' : 'Avaliar'}
                                        </Button>
                                    </div>
                                )
                            }
                        </>
                }
            </FormLayout>
            <Modal
                size={'mdm'}
                title={"Avaliar Atendimento"}
                footer={!isRated}
                btnOnclickName={'Enviar Avaliação'}
                onClick={() => onSaveRating()}
                btnRowReverse={true}
                {...modalProps}
            >
                {
                    loading ?
                        <span className="flex items-center justify-center h-7">
                            <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </span>
                        :
                        <>
                            <div className={'flex w-full justify-center'}>
                                <div className={'col-span-4 flex items-center md:p-3 md:ml-3 md:gap-2'}>
                                    <InputRating value={state.rating} setValue={(value) => setState({...state, rating: value})} />
                                </div>

                            </div>
                            <div className={'sm:w-full grid p-3'}>
                                <div className="grid grid-cols-12 gap-3">
                                </div>
                            </div>
                        </>
                }
            </Modal>
        </>
    )
}