import api, {listAll} from './api';

const endpoint = '/notification';

export default {
    listAll: async () => listAll<any>({endpoint}),
    
    async getNotificationsUser() {
        try {
            const url = `${endpoint}/client`
            const {data} = await api.get(url);
            return {data, isError: false};
        } catch (err: any) {
            return {
                data: err?.response?.data ?? {message: 'Falha ao processar requisição'},
                isError: true
            };
        }
    }
}