
import {
    File,
    Image02,
    PencilEdit,
    SearchSquare,
    UserSquare
} from "../../../components/Icons";
import FormLayout from "../../../components/layout/FormLayout";
import React, {useEffect, useState} from "react";
import clientApi from "../../../services/clientApi";
import {useParams} from "react-router";
import ViaCepService from "../../../services/viaCepApi";
import InputImage from "../../../components/inputs/InputImage";
import Input from "../../../components/inputs/Input";
import typePropertyApi from "../../../services/typePropertyApi";
import InputSelect from "../../../components/inputSelect";
import workStatusApi from "../../../services/workStatusApi";
import propertyApi from "../../../services/propertyApi";
import Str from "../../../helpers/Str";
import useModal from "../../../hooks/useModal";
import Modal from "../../../components/modal/Modal";
import Alert from "../../../helpers/Alert";
import { IoClose } from "react-icons/io5";
import CardClient from "../../../components/cardClient";
import DocumentButton from "../../../components/button/DocumentButton";
import {CgSpinner} from "react-icons/cg";
import postApi from "../../../services/postApi";
import {useNavigate} from "react-router-dom";
import PostComponent from "../../../components/PostComponent";
import stepWorckApi from "../../../services/stepWorckApi";
import Storage from "../../../helpers/Storage";
export default function FormProprieties() {
    const defaltValues = {
        id: '',
        description: '',
        id_type_property: '',
        id_status_constructions: '',
        status: 1,
        address: '',
        number: '',
        block: '',
        lot: '',
        complement: '',
        neighborhood: '',
        cep: '',
        city: '',
        state: '',
        work_registration: 1,
        image: '',
        created_at: '',
        clients: [],
        clientsScreen: [],
        earthworks_support: 0,
        gray_work: 0,
        finish: 0,
    }
    //let yourDate = new Date().toISOString().split('T')[0]
    const navigate = useNavigate();
    const [state, setState]: any = useState(defaltValues);
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [loadingClients, setLoadingClients] = useState(false);
    const [accessProfileOptions, setAccessProfileOptions]: any = useState();
    const [workStatusOptions, setWorkStatusOptions]: any = useState();
    const [linkedClient, setLinkedClient] = useState([]);
    const [clientOptions, setClientOptions]: any = useState([]);
    const [stepWorck, setStepWorck]: any = useState({
        earthworks_support: 5,
        gray_work: 60,
        finish: 35,
    });

    const [screenModal, setScreenModal]: any = useState({
        number: null,
        size: '',
        title: '',
        btnOnclickName: ''
    });
    const [postImovel, setPostImovel]: any = useState({
        description: '',
        properties: [],
        images: [],
        documents: [],
    });

    const [totalPercent, setTotalPercent] = useState(0);
    const permissions: any = Storage.getUserPermissions();
    const editValidation = permissions?.includes('property.edit');

    const { modalProps, open, close } = useModal();
    const cepSrv = new ViaCepService();

    const workRegistrationOptions = [
        { value: 1, label: 'Sim' },
        { value: 0, label: 'Não' },
    ];
    const StatusOption = [
        { value: 1, label: 'Ativo' },
        { value: 0, label: 'Inativo' },
    ];

    const onRemoveImage = () => {
        const newState = {...state};
        newState.image = '';
        setState(newState);
    }

    const handleChange = (value: any, input: string) => {
        if(input === 'earthworks_support' || input === 'gray_work' || input === "finish"){
            if (value > 100){
                value = 100
            }
        }
        setState({...state, [input]: value});
    };

    const getDatas = async () => {
        const [typePropertyData, workStatusData, clientData, stepWorckData] = await Promise.all([
            typePropertyApi.listAll(),
            workStatusApi.listAll(),
            clientApi.listAll('/usuarios?where=type,=,cliente'),
            //propertyApi.listAll()
            stepWorckApi.listAll()
        ])
        setPostImovel({...postImovel, ['properties']: [params.id]})
        if (!typePropertyData.isError){
            setAccessProfileOptions(typePropertyData.data.map((dt:any) => {return {value: dt.id, label: dt.description}}))
        }

        if (!workStatusData.isError){
            setWorkStatusOptions(workStatusData.data.map((dt:any) => {return {value: dt.id, label: dt.description}}))
        }

        if (!clientData.isError){
            setClientOptions(clientData.data.map((dt:any) => {return {value: dt.id, label: dt.name}}))
        }
        if (!stepWorckData.isError){
            if (stepWorckData.data){
                setStepWorck(
                    {
                        earthworks_support: stepWorckData.data[0]['percentage'],
                        gray_work: stepWorckData.data[1]['percentage'],
                        finish: stepWorckData.data[2]['percentage'],
                    }
                )
            }
        }


    }

    const getState = async () => {
        // @ts-ignore
        const {data, isError} = await propertyApi.one(params.id.toString(), 'relations=clients');

        setState({
            id: data.id,
            description: data.description,
            id_type_property: data.id_type_property,
            id_status_constructions: data.id_status_constructions,
            status: data.status ? 1 : 0,
            address: data.address,
            number: data.number,
            block: data.block,
            lot: data.lot,
            complement: data.complement,
            neighborhood: data.neighborhood,
            cep: data.cep,
            city: data.city,
            state: data.state,
            work_registration: data.work_registration ? 1 : 0,
            image: data.photo_path,
            created_at: Str.convertDateCreatedAt(data.created_at),
            clients: [],
            clientsScreen: data.clients,
            earthworks_support: data.earthworks_support,
            gray_work: data.gray_work,
            finish: data.finish,
        })
        const calc_earthworks_support = (stepWorck['earthworks_support']/100) * data.earthworks_support;
        const calc_gray_work = (stepWorck['gray_work']/100) * data.gray_work;
        const calc_finish = (stepWorck['finish']/100) * data.finish;

        const totalCalc = calc_earthworks_support + calc_gray_work + calc_finish
        setTotalPercent(totalCalc);

        setLoading(false)
    }

    useEffect(() => {

        getDatas()
        if (params.id){
            getState()
        }else{
            setLoading(false)
        }

    },[])

    const handleChangeCep = (value: string) => {
        cepSrv.consultaCep(state.cep).then((cepResponse: any) =>{
            if (!cepResponse.data.erro){
                let newState = {...state};

                newState.address = cepResponse.data.logradouro;
                newState.neighborhood = cepResponse.data.bairro;
                newState.city = cepResponse.data.localidade;
                newState.state = cepResponse.data.uf;
                setState(newState);
            }
        });
    }



    const onChangeLinkedClient = (data: any) =>{
        setLinkedClient(data);
    }

    const onClickOpenModal = (number: number) => {
        let screenModal: any = {
            number: null,
            size: '',
            title: '',
            btnOnclickName: '',
        }
        if (number === 1){
            screenModal = {
                number: number,
                size: 'mdm',
                title: 'Criar postagem de atualização',
                btnOnclickName: 'Postar',
            }
        }
        if (number === 2){
            screenModal = {
                number: number,
                size: 'mds',
                title: 'Vincular Clientes ao Imóvel',
                btnOnclickName: 'Vincular',
            }
        }
        setScreenModal(screenModal);
        open();
    }

    const onClickModal = () => {
        if (screenModal.number === 2) {
            linkClient()
        }
    }


    const linkClient = async () => {

        const dataForm = {
            clients : linkedClient
        }
        if (state.id){
            const confirm = await Alert.confirm(
                'Tem certeza que deseja realizar esse vínculo?',
                `Vincular Cliente?`
            );
            if (! confirm) return;
            setLoading(true)
            const {data, isError} = await propertyApi.update(state.id, dataForm);

            if (!isError){
                /*setState(data);*/
                const newState = {...state};
                newState.clientsScreen = data.clients
                setState(newState);
                setLinkedClient([]);
                setLoading(false)
                Alert.success('Vinculado com sucesso!')
                close()
            }
            setLoading(false)
        }else{
            Alert.error('Necessário Cadastrar o imovel')
        }

    }

    async function onClickUnlinkClient(clientData: any) {
        const confirm = await Alert.confirm(
            'Tem certeza que deseja desnvicular esse cliente?',
            `Desvincular Cliente?`
        );
        if (!confirm) return;
        setLoading(true)
        const {data, isError} = await propertyApi.unlinkClient(state.id, clientData.id);
        if (!isError){
            const newState = {...state};
            newState.clientsScreen = data.clients
            setState(newState);
        }
        setLoading(false)
    }

    const calcPercent = () =>{
        const calc_earthworks_support = (stepWorck['earthworks_support']/100) * state.earthworks_support;
        const calc_gray_work = (stepWorck['gray_work']/100) * state.gray_work;
        const calc_finish = (stepWorck['finish']/100) * state.finish;
        const totalCalc = calc_earthworks_support + calc_gray_work + calc_finish
        setTotalPercent(totalCalc);
    }



    return(
        <>
            <FormLayout
                title={'Cadastro de Imóvel'}
                urlReturn={'/propriedades'}
                state={state}
                setState={setState}
                apiData={propertyApi}
                defaltValues={defaltValues}
                permission={'property.edit'}
                sideMenu={
                    <>
                        <button disabled={loading || !editValidation} onClick={() => onClickOpenModal(1)} className={'flex gap-2 items-center text-[14px] hover:text-system-primary'}>
                            <PencilEdit className={'text-[22px]'}/>Gerar Atualização
                        </button>

                        <button onClick={() => params.id ? navigate(`/propriedades/${params.id}/posts`) : console.log('')} disabled={loading} className={'flex gap-2 items-center text-[14px] hover:text-system-primary'}>
                            <SearchSquare className={'text-[22px]'}/>Ver Todas as atualizações
                        </button>

                        <button disabled={loading || !editValidation} onClick={() => onClickOpenModal(2)} className={'flex gap-2 items-center text-[14px] hover:text-system-primary'}>
                            <UserSquare  className={'text-[22px]'}/>Adicionar Cliente
                        </button>
                    </>
                }
            >
                {
                    loading ?
                        <span className="flex items-center justify-center h-7">
                            <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </span>
                        :
                        <>
                            <div className={'flex  w-full '}>
                                <div className={'sm:w-full grid p-3'}>
                                    <div className="mt-3 grid grid-cols-12 gap-3">
                                        <div className={'md:col-span-2 xs:col-span-12 lg:col-span-2 flex items-center md:p-3 md:ml-3 lg:ml-0 md:gap-2'}>
                                            <InputImage
                                                imgSquare={true}
                                                homeIcon={true}
                                                urlPrevew={state.image}
                                                onAddImage={(v) => handleChange(v, 'image')}
                                                onRemoveImage={() => onRemoveImage()}
                                            />
                                        </div>
                                        <Input
                                            label={'Descrição'}
                                            value={state.description}
                                            required
                                            className={'md:col-span-7 xs:col-span-12 lg:col-span-5 content-center'}
                                            setValue={(v) => handleChange(v, 'description')}
                                        />
                                        <Input
                                            label={'Data de criação'}
                                            disabled
                                            value={state.created_at}
                                            className={'md:col-span-2 xs:col-span-12 content-center'}
                                        />
                                        <InputSelect
                                            key={(Math.random() + 1).toString(36).substring(7)}
                                            selected={state.id_type_property}
                                            label={'Tipo de imóvel'}
                                            required
                                            className={'md:col-span-3 xs:col-span-12 content-center'}
                                            options={accessProfileOptions}
                                            onChangeSelect={(e: any) => handleChange(e.value, 'id_type_property')}
                                            placeholder={'Tipo de imóvel'}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={'border-b border-system-gray-gray80'}></div>
                            <div className={'sm:w-full grid p-3'}>

                                <div className="grid grid-cols-12 gap-3">
                                    <Input
                                        label={'CEP'}
                                        inputType={'CEP'}
                                        value={state.cep}
                                        required
                                        setValue={(v) => handleChange(v, 'cep')}
                                        OnBlur={(e: any) => handleChangeCep(e.target.value)}
                                        className={'md:col-span-2 xs:col-span-12'}
                                    />
                                    <Input
                                        label={'Endereço'}
                                        value={state.address}
                                        required
                                        className={'md:col-span-4 xs:col-span-12'}
                                        setValue={(v) => handleChange(v, 'address')}
                                    />
                                    <Input
                                        label={'Nº'}
                                        value={state.number}
                                        className={'md:col-span-1 xs:col-span-12'}
                                        setValue={(v) => handleChange(v, 'number')}
                                    />
                                    <Input
                                        label={'Qd'}
                                        value={state.block}
                                        className={'md:col-span-1 xs:col-span-12'}
                                        setValue={(v) => handleChange(v, 'block')}
                                    />
                                    <Input
                                        label={'Lote'}
                                        value={state.lot}
                                        className={'md:col-span-1 xs:col-span-12'}
                                        setValue={(v) => handleChange(v, 'lot')}
                                    />
                                    <Input
                                        label={'Complemento'}
                                        value={state.complement}
                                        className={'md:col-span-3 xs:col-span-12'}
                                        setValue={(v) => handleChange(v, 'complement')}
                                    />
                                </div>

                                <div className="grid grid-cols-12 gap-3 mt-5">
                                    <Input
                                        label={'Setor'}
                                        required
                                        value={state.neighborhood}
                                        className={'md:col-span-4 xs:col-span-12'}
                                        setValue={(v) => handleChange(v, 'neighborhood')}
                                    />

                                    <Input
                                        label={'Estado'}
                                        required
                                        value={state.state}
                                        className={'md:col-span-3 xs:col-span-12'}
                                        setValue={(v) => handleChange(v, 'state')}
                                    />
                                    <Input
                                        label={'Cidade'}
                                        required
                                        value={state.city}
                                        className={'md:col-span-3 xs:col-span-12'}
                                        setValue={(v) => handleChange(v, 'city')}
                                    />
                                    <InputSelect
                                        key={(Math.random() + 1).toString(36).substring(7)}
                                        selected={state.status}
                                        label={'Status'}
                                        required
                                        className={'md:col-span-2 xs:col-span-12 content-center'}
                                        options={StatusOption}
                                        onChangeSelect={(e: any) => handleChange(e.value, 'status')}
                                        placeholder={'Status'}
                                    />
                                </div>


                                <div className="grid grid-cols-12 gap-3 mt-5">
                                    <InputSelect
                                        key={(Math.random() + 1).toString(36).substring(7)}
                                        selected={state.work_registration}
                                        label={'Cadastro de Obra?'}
                                        required
                                        className={'md:col-span-3 xs:col-span-12 content-center'}
                                        options={workRegistrationOptions}
                                        onChangeSelect={(e: any) => handleChange(e.value, 'work_registration')}
                                        placeholder={'Cadastro de Obra?'}
                                    />

                                    <InputSelect
                                        key={(Math.random() + 1).toString(36).substring(7)}
                                        selected={state.id_status_constructions}
                                        label={'Status da Obra'}
                                        required
                                        className={'md:col-span-3 xs:col-span-12 content-center'}
                                        options={workStatusOptions}
                                        onChangeSelect={(e: any) => handleChange(e.value, 'id_status_constructions')}
                                        placeholder={'Status da Obra'}
                                    />
                                </div>
                                <div className="grid grid-cols-12 gap-3 mt-5">
                                    <Input
                                        label={`Terraplanagem e Arrimo`}
                                        value={state.earthworks_support}
                                        type={'number'}
                                        max={100}
                                        required
                                        className={'md:col-span-2 xs:col-span-12 lg:col-span-3 content-center'}
                                        setValue={(v) => handleChange(v, 'earthworks_support')}
                                        OnBlur={() => calcPercent()}
                                    />
                                    <Input
                                        label={'Obra Cinza'}
                                        value={state.gray_work}
                                        type={'number'}
                                        required
                                        className={'md:col-span-2 xs:col-span-12 lg:col-span-3 content-center'}
                                        setValue={(v) => handleChange(v, 'gray_work')}
                                        OnBlur={() => calcPercent()}
                                    />
                                    <Input
                                        label={'Acabamento '}
                                        value={state.finish}
                                        type={'number'}
                                        required
                                        className={'md:col-span-2 xs:col-span-12 lg:col-span-3 content-center'}
                                        setValue={(v) => handleChange(v, 'finish')}
                                        OnBlur={() => calcPercent()}
                                    />
                                    <Input
                                        label={'Total da obra'}
                                        value={totalPercent}
                                        disabled
                                        className={'md:col-span-6 xs:col-span-12 lg:col-span-3 content-center'}
                                    />
                                </div>
                            </div>
                            {
                                state.clientsScreen.length ?
                                    <>
                                        <div className={'border-b border-system-gray-gray80'}></div>
                                        <div className={'flex flex-col gap-3 p-3'}>
                                            <div className={'text-sm'}>
                                                Clientes Vinculados
                                            </div>
                                            <div className={'flex flex-wrap gap-3 w-full'}>
                                                {
                                                    loadingClients ?
                                                        <span className="flex items-center justify-center h-7">
                                                            <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                            </svg>
                                                        </span>
                                                        :

                                                    state.clientsScreen.map((client: any) => {
                                                        return(
                                                            <CardClient
                                                                id={client.id}
                                                                name={client.name}
                                                                url_photo={client.photo_path}
                                                                onClickUnlinkClient={() => onClickUnlinkClient(client)}
                                                                /*onClickCard={() => navigate(`/clientes/formulario/${client.id}`)}*/
                                                            />
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </>
                                :
                                    ''
                            }
                        </>
                }
            </FormLayout>
            <Modal
                size={screenModal.size}
                title={screenModal.title}
                footer={screenModal.number != 1}
                btnOnclickName={screenModal.btnOnclickName}
                onClick={() => onClickModal()}
                btnRowReverse={true}
                {...modalProps}
            >
                <div className={'p-2'}>
                    {
                        screenModal ?
                            screenModal.number === 1 ?
                                <PostComponent idProperty={parseInt(params.id as string)} closeModal={close} btnCancel={true}/>
                                :
                                <div className={''}>
                                    <InputSelect
                                        disabled={!editValidation}
                                        isMulti={true}
                                        key={(Math.random() + 1).toString(36).substring(7)}
                                        value={linkedClient}
                                        label={'Selecione cliente'}
                                        className={'md:col-span-3 xs:col-span-12 content-center p-3'}
                                        options={clientOptions}
                                        setValue={(e: any) => onChangeLinkedClient(e)}
                                        placeholder={'Selecione cliente'}
                                    />
                                </div>

                            :
                            ''
                    }

                </div>
            </Modal>
        </>
    )
}