import {
    AddCircleHalfDot,
    Delete,
    Doc,
    DocumentAttachment,
    Download,
    Pdf,
    SearchSquare
} from "../../../components/Icons";
import FormLayout from "../../../components/layout/FormLayout";
import React, {useEffect, useState} from "react";
import filesApi from "../../../services/filesApi";
import Input from "../../../components/inputs/Input";
import InputSelect from "../../../components/inputSelect";
import typeFileApi from "../../../services/typeFileApi";
import propertyApi from "../../../services/propertyApi";
import Modal from "../../../components/modal/Modal";
import useModal from "../../../hooks/useModal";
import DocumentButton from "../../../components/button/DocumentButton";
import Str from "../../../helpers/Str";
import Alert from "../../../helpers/Alert";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import Storage from "../../../helpers/Storage";

export default function FormFiles() {
    const params = useParams();
    const hoje = new Date().toISOString().slice(0, 10);
    const initialState = {
        id: '',
        id_folder: params.folderId || null,
        description: '',
        id_file_type: '',
        created_at: hoje,
        observation: '',
        properties: [],
        files: [],
        documents_remove: []
    }

    const navigate = useNavigate();
    const [state, setState]: any = useState(initialState);
    const [typeFileOptions, setTypeFileOptions]: any = useState([]);
    const [propertiesOptions, setPropertiesOptions]: any = useState([]);
    const [loading, setLoading] = useState(true);
    const [filesScreen, setFilesScreen] = useState([]);
    const { modalProps, open, close } = useModal();
    
    useEffect(() => {
        getDatas()
        if (params.id) {
            getState()
        }else{
            setLoading(false)
        }

    }, [])

    const getState = async () => {
        if (params.id){
            const {data, isError} = await filesApi.one(params.id.toString(), 'relations=archives')
            if (!isError){
                setState({
                    ...state,
                    id: data.id,
                    description: data.description,
                    id_file_type: data.id_file_type,
                    created_at: Str.removeTDateCreatedAt(data.created_at),
                    observation: data.observation,
                    files: data.archives,
                    documents_remove: []
                })
                setFilesScreen(data.archives)
            }

        }
        setLoading(false)
    }


    const getDatas = async () => {
        const [typeFileData, propertiesData] = await Promise.all([typeFileApi.listAll(), propertyApi.listAll()]);
        if (!typeFileData.isError){
            setTypeFileOptions(typeFileData.data.map((dt:any) => {return {value: dt.id, label: dt.description}}))
        }

        if (!propertiesData.isError){
            setPropertiesOptions(propertiesData.data.map((dt:any) => {return {value: dt.id, label: dt.description}}))
        }
    }

    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };

    const onClickModal = async () => {
        close()
    }


    const onSelectFiles = (data: any) => {
        const newState = {...state};

        let filesData = data
        let filesBase64: any[] = []
        if (filesScreen.length){
            filesData = data.concat(filesScreen);
        }
        data.map((img: any) => {
            Str.convertFileToBase64(img, (str: any) => filesBase64.push(str), true)
        })
        setFilesScreen(filesData);

        if (newState.files.length){
            filesBase64 = newState.files.concat(filesBase64)
        }
        newState.files = filesBase64
        setState(newState)

    }

    const removeFile = async (file: any) => {

        const confirm = await Alert.confirm(
            'Tem certeza que deseja remover esse aquivo?',
            `Excluir esse aquivo?`
        );
        if (! confirm) return;
        const newState = {...state};
        const newFileScreen = [...filesScreen]
        if (file.id) {
            newState.documents_remove.push(file)
            newState.files = newState.files.filter((st: any) => st.original_name !== file.original_name)
        }else{
            newState.files = newState.files.filter((st: any) => st.original_name !== file.name)
        }
        setState(newState);

        setFilesScreen(newFileScreen.filter((st: any) => (st.name ? st.name : st.original_name) !== (file.name ? file.name : file.original_name)))

    }
    const permissions: any = Storage.getUserPermissions();
    const editValidation = permissions?.includes('files.edit');
    return(
        <>
            <FormLayout
                title={'Cadastro de Arquivos'}
                urlReturn={`/pastas/${params.folderId}/arquivos`}
                state={state}
                setState={setState}
                apiData={filesApi}
                defaltValues={initialState}
                functionNewData={() => setFilesScreen([])}
                permission={'files.edit'}
                sideMenu={
                    <>
                        <button disabled={loading || editValidation}  type={'button'} onClick={() => open()} className={'flex gap-2 items-center text-[14px] hover:text-system-primary'}>
                            <DocumentAttachment className={'text-[22px]'}/>Vincular Imóvel
                        </button>

                        <button disabled={!state.id} onClick={() => params.id ? navigate(`/pastas/${params.folderId}/arquivos/${params.id}/propriedades`) : console.log('')}  className={'flex gap-2 items-center text-[14px] hover:text-system-primary'}>
                            <SearchSquare className={'text-[22px]'}/>Ver Imóveis Vinculados
                        </button>
                    </>
                }
            >
                {
                    loading ?
                        <span className="flex items-center justify-center h-7">
                            <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </span>
                        :
                        <>
                            <div className={'flex  w-full '}>
                                <div className={'sm:w-full grid p-5'}>
                                    <div className="grid grid-cols-12 gap-3">

                                        <Input
                                            label={'Descrição do documento'}
                                            value={state.description}
                                            required
                                            className={'md:col-span-5 xs:col-span-12'}
                                            setValue={(v) => handleChange(v, 'description')}
                                        />
                                        <InputSelect
                                            required
                                            key={(Math.random() + 1).toString(36).substring(7)}
                                            selected={state.id_file_type}
                                            label={'Tipo de documento'}
                                            className={'md:col-span-4 xs:col-span-12'}
                                            options={typeFileOptions}
                                            onChangeSelect={(e: any) => handleChange(e.value, 'id_file_type')}
                                            placeholder={'Tipo de documento'}
                                        />
                                        <Input
                                            label={'Data de criação'}
                                            disabled
                                            value={state.created_at}
                                            type={'date'}
                                            className={'md:col-span-3 xs:col-span-12'}
                                        />
                                    </div>
                                    <div className="grid grid-cols-1 gap-3 mt-2">
                                        <div>
                                            <label>Observação</label>
                                                <textarea
                                                    onChange={(e) => handleChange(e.target.value, 'observation')}
                                                    rows={5}
                                                    placeholder="Observação do arquivo"
                                                    className={' p-2 w-full text-[#939396] placeholder-[#939396] rounded-xl bg-system-blue-dark focus:border-border transition'}
                                                >
                                                {state.observation}
                                            </textarea>
                                        </div>

                                    </div>
                                    {
                                        filesScreen.length ? ""
                                            :
                                            <div className={'grid grid-cols-12 text-[25px] text-system-primary mt-4'}>
                                                <DocumentButton
                                                    classeName={'hover:text-[#49E268]'}
                                                    onSelect={(e: any) => onSelectFiles(e)}
                                                    id={'files'}
                                                    icon={<AddCircleHalfDot/>}
                                                    accept={'.doc, .docx,.ppt, .pptx,.txt,.pdf'}
                                                />
                                            </div>
                                    }

                                    {
                                        filesScreen.length ?
                                        <div className={'flex flex-wrap gap-5 mt-5'}>
                                            {
                                                filesScreen.map((file: any) => {
                                                    const icon  = (file.type === 'application/pdf' || file.type === 'pdf') ? <Pdf className={'text-border text-[35px]'}/> : <Doc className={'text-border text-[35px]'}/>
                                                    return  (
                                                        <div className={'flex gap-3'}>
                                                            <div className={'flex w-[25rem] border border-[#13131A] hover:border-border items-center justify-between w-full p-3 h-[73px] rounded-xl bg-[#13131A]'}>
                                                                <div className={'text-[#B5B5BE] flex gap-2 items-center'}>
                                                                    {icon} <div className={'text-[14px]'}>{file.name ? file.name : file.original_name}</div>
                                                                </div>
                                                                <div className={'flex text-[#64646C] gap-4'}>
                                                                    <div >
                                                                         {Str.convertDateCreatedAt(file.created_at)}
                                                                    </div>
                                                                    {
                                                                        file.original_name ?
                                                                            <div>
                                                                                <button type={'button'} onClick={()=> window.open(file.file_path, "_blank")} className={'hover:text-border'}>
                                                                                    <Download/>
                                                                                </button>
                                                                            </div>
                                                                            :
                                                                            ''
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className={'flex flex-col gap-3 justify-center text-[20px] text-system-primary'}>
                                                                <DocumentButton
                                                                    classeName={'hover:text-[#49E268]'}
                                                                    onSelect={(e: any) => onSelectFiles(e)}
                                                                    id={'files'}
                                                                    icon={<AddCircleHalfDot/>}
                                                                    accept={'.doc, .docx,.ppt, .pptx,.txt,.pdf'}
                                                                />
                                                                <button type={'button'} onClick={() => removeFile(file)} className={'hover:text-[#F44E4D]'}>
                                                                    <Delete/>
                                                                </button>
                                                            </div>
                                                        </div>

                                                    )
                                                })
                                            }
                                        </div>
                                        :
                                        ''
                                    }
                                </div>
                            </div>
                        </>
                }
            </FormLayout>
            <Modal
                size={'mds'}
                title={"Vincular Documento ao Imóvel"}
                footer={true}
                btnOnclickName={'Vincular'}
                onClick={() => onClickModal()}
                btnRowReverse={true}
                {...modalProps}
            >
                <div className={'w-full p-5'}>
                    <InputSelect
                        isMulti={true}
                        key={(Math.random() + 1).toString(36).substring(7)}
                        value={state.properties}
                        label={'Selecione o(s) imóvel(is)'}
                        className={'content-center'}
                        options={propertiesOptions}
                        setValue={(e: any) => handleChange(e, 'properties')}
                        placeholder={'Selecione o(s) imóvel(is)'}
                    />
                </div>

            </Modal>
        </>
    )
}