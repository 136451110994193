import InputSelect from "../inputSelect";
import Input from "../inputs/Input";
import DocumentButton from "../button/DocumentButton";
import {File, Image02} from "../Icons";
import {IoClose} from "react-icons/io5";
import React, {useEffect, useState} from "react";
import typePropertyApi from "../../services/typePropertyApi";
import workStatusApi from "../../services/workStatusApi";
import clientApi from "../../services/clientApi";
import propertyApi from "../../services/propertyApi";
import Button from "../button/Button";
import postApi from "../../services/postApi";
import Alert from "../../helpers/Alert";
import Str from "../../helpers/Str";
interface IPostComponent {
    idProperty?: number | string,
    onSubmit?: any;
    closeModal?: any;
    post?: any;
    onFunctionAfterEdit?: any;
    className?: string;
    btnCancel?: boolean;
}
export default function PostComponent({idProperty, onSubmit, closeModal, post, onFunctionAfterEdit, className, btnCancel}: IPostComponent) {
    const initialData = {
        description: '',
        properties: [],
        image: [],
        files: []
    }
    const [postImovel, setPostImovel]: any = useState({
        id: post ? post.id : '',
        description: post ? post.description : '',
        properties: [],
        images: post ? post.files.filter((file:any) => file.type === 'image') : [],
        documents: post ? post.files.filter((file:any) => file.type === 'file') : [],
        images_remove: [],
        documents_remove: [],
    });
    const [loading, setLoading] = useState(true);
    const [imagesPostScreen, setImagesPostScreen]: any = useState(post ? post.files.filter((file:any) => file.type === 'image') : []);
    //const [imagesPost, setImagesPost]: any = useState([]);
    const [documentsPost, setDocumentsPost]: any = useState(post ? post.files.filter((file:any) => file.type === 'file') : []);
    const [propertiesSelected, setPropertiesSelected]: any = useState(post ? post.properties.map((property: any) => property.id): [idProperty]);
    const [propertiesOptions, setPropertiesOptions]: any = useState();
    const [imageRemove, setImageRemove] = useState([])
    const [fileRemove, setFileRemove] = useState([])

    const handleChangePost = (value: any, input: string) => {
        setPostImovel({...postImovel, [input]: value});
    };

    const getDatas = async () => {
        const [dataProperty] = await Promise.all([
            propertyApi.listAll()
        ])

        if (!dataProperty.isError){
            setPropertiesOptions(dataProperty.data.map((dt:any) => {return {value: dt.id, label: dt.description}}))
        }

        setLoading(false)
    }

    useEffect(()=>{
        getDatas()
    }, [])

    const onSelectImages = async (data: any) => {

        const newPostImovel = {...postImovel}
        let imagesData: any[] = []
        let imagesbase64: any[] = []
        imagesData = data.map((file: any) => {
            return(
                {
                    url_path: URL.createObjectURL(file),
                    original_name: file.name
                }
            )
        })

        if (imagesPostScreen.length){
            imagesData = imagesData.concat(imagesPostScreen);
        }

        data.map((img: any) => {
            convertFileToBase64(img, (str: any) => imagesbase64.push({
                url_path: str,
                original_name: img.name
            }))
        })

        if (newPostImovel.images.length){
            imagesbase64 = newPostImovel.images.concat(imagesbase64)
        }
        newPostImovel.images = imagesbase64
        setPostImovel(newPostImovel)

        setImagesPostScreen(imagesData)

    }

    const onRemoveImg = async (image: any) => {
        if (image.id){
            const newImageRemove = [...imageRemove];
            // @ts-ignore
            newImageRemove.push(image)
            setImageRemove(newImageRemove)
        }
        const newImagePostScreen = [...imagesPostScreen];
        const newPostImovel = {...postImovel}
        newPostImovel.images = newPostImovel.images.filter((ips: any, indexPostImovel: number) => image.original_name != ips.original_name);
        setPostImovel(newPostImovel)
        setImagesPostScreen(newImagePostScreen.filter((ips: any, indexIps: number) => image.original_name != ips.original_name))
    }
    function convertFileToBase64(file: any, callback: any, originalName?: boolean) {

        const reader = new FileReader();
        reader.readAsDataURL(file);
        if (originalName){
            reader.onloadend = () => callback({
                file: reader.result,
                original_name: file.name
            });
        }else{
            reader.onloadend = () => callback(reader.result);
        }

    }

    const onSelectFiles = (data: any) => {

        const newPostImovel = {...postImovel}
        let filesData = data
        let filesBase64: any[] = []
        if (documentsPost.length){
            filesData = data.concat(documentsPost);
        }
        setDocumentsPost(filesData);
        data.map((img: any) => {
            convertFileToBase64(img, (str: any) => filesBase64.push(str), true)
        })

        if (newPostImovel.documents.length){
            filesBase64 = newPostImovel.documents.concat(filesBase64)
        }
        newPostImovel.documents = filesBase64
        setPostImovel(newPostImovel)
    }

    const onRemoveFile = async (file: any) => {
        const confirm = await Alert.confirm(
            'Tem certeza que deseja remover esse aquivo?',
            `Excluir esse aquivo?`
        );
        if (!confirm) return;
        const newDocumentsPost = [...documentsPost]
        if (file.id) {
            const newFileRemove = [...fileRemove];
            // @ts-ignore
            newFileRemove.push(file)
            setFileRemove(newFileRemove)
        }
        const newPostImovel = {...postImovel}
        newPostImovel.documents = newPostImovel.documents.filter((ips: any) => file.original_name != ips.original_name);
        setPostImovel(newPostImovel)
        setDocumentsPost(newDocumentsPost.filter((doc: any) => file.original_name != doc.original_name));
    }

    const salvePostPropertie = async () => {
        setLoading(true)
        postImovel.properties = propertiesSelected;
        if (postImovel.id){
            if (fileRemove.length){
                postImovel.documents_remove = fileRemove;
            }
            if (imageRemove.length){
                postImovel.images_remove = imageRemove;
            }
            const {data, isError} = await postApi.update(postImovel.id, postImovel);
            if (!isError){
                Alert.success('Post foi editado com sucesso!');
                if (closeModal){
                    closeModal()
                }else{
                    setPropertiesSelected([])
                }
                setPostImovel({
                    description: '',
                    properties: [idProperty],
                    images: [],
                    documents: [],
                })
                setPostImovel([])
                setDocumentsPost([])
                setImagesPostScreen([])
            }
        }else{
            const {data, isError} = await postApi.create(postImovel);
            if (!isError){
                Alert.success('Post foi criado com sucesso!');
                setPostImovel({
                    description: '',
                    properties: [idProperty],
                    images: [],
                    documents: [],
                })
                setPostImovel([])
                setDocumentsPost([])
                setImagesPostScreen([])
                if (closeModal){
                    closeModal()
                }else{
                    setPropertiesSelected([])
                }
                /*!btnCancel ?? closeModal();*/
            }
        }
        setLoading(false)
    }



    let yourDate = new Date()
    if (loading){
        return (
            <div>
                <span className="flex items-center justify-center h-7">
                    <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-border" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </span>
            </div>
        )
    }

    return(
        <div className={className}>
            <div className={' p-3 flex flex-col w-full gap-5'}>
                <div className={'flex w-full'}>
                <textarea
                    onChange={(e) => handleChangePost(e.target.value, 'description')}
                    rows={5}
                    placeholder="Descreva sua postagem"
                    className={' p-1 w-full text-[#939396] placeholder-[#939396] bg-system-blue-dark focus:border-border transition'}
                >
                    {postImovel.description}
                </textarea>
                </div>
                <div className={'flex w-full'}>
                    <InputSelect
                        isMulti={true}
                        key={(Math.random() + 1).toString(36).substring(7)}
                        value={propertiesSelected}
                        label={'Selecione o Imóvel'}
                        className={'content-center w-full '}
                        options={propertiesOptions}
                        setValue={(e: any) => setPropertiesSelected(e)}
                        placeholder={'Selecione Imóvel'}
                    />
                </div>
                <div className="grid grid-cols-12 gap-5">
                    <Input
                        label={'Data de criação'}
                        type={'date'}
                        disabled
                        value={post ? post.created_at.split("T")[0] : yourDate.toISOString().split('T')[0]}
                        className={'md:col-span-3 xs:col-span-12 content-center'}
                    />
                    <div className={'md:col-span-6 xs:col-span-12 flex gap-4 items-center content-center md:pt-5 content-center text-[25px] text-system-primary'}>
                        <DocumentButton
                            onSelect={(e: any) => onSelectImages(e)}
                            id={'images-properties'}
                            icon={<Image02/>}
                            accept={'image/*'}
                        />
                        <DocumentButton
                            onSelect={onSelectFiles}
                            id={'files-properties'}
                            icon={<File/>}
                            accept={'.doc, .docx,.ppt, .pptx,.txt,.pdf'}
                        />
                    </div>

                </div>
                {

                    imagesPostScreen.length ?
                        <div className={'flex flex-wrap gap-3'}>
                            {
                                imagesPostScreen.map((image: any, index: number) => {
                                    return  <div className={'border-2 border-border p-1 rounded-md relative'}>
                                        <button onClick={() => onRemoveImg(image)} className={'w-[20px] h-[20px] absolute text-system-primary top-1  rounded-full justify-center'}>
                                            <IoClose/>
                                        </button>
                                        <img className={'w-[100px] h-[100px]'} src={image.url_path}/>
                                    </div>
                                })
                            }

                        </div>
                        :''
                }

                {

                    documentsPost.length ?
                        <div className={'flex flex-wrap gap-3'}>
                            {
                                documentsPost.map((document: any, index: number) => {
                                    return  (
                                        <div className={'border-2 text-[11px] border-border rounded-md relative '}>
                                            <div className={'w-full'}>
                                                <button onClick={() => onRemoveFile(document)} className={'w-[20px] h-[20px] absolute text-system-primary top-0  rounded-full justify-center'}>
                                                    <IoClose/>
                                                </button>
                                                <div className={'w-full p-3'}>
                                                    {document.name ? document.name : document.original_name}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                        :''
                }

            </div>
            <footer className={`p-5 border-t ${className ? ''  : 'bg-system-blue-dark'} border-system-gray-gray80 flex ${btnCancel ? 'justify-between' : 'justify-end'}   rounded-b-xl`}>
                {
                    btnCancel ?
                        <Button onClick={() => closeModal()} className={'w-[180px] h-[36px] border border-border bg-system-blue-dark text-system-primary'}>Cancelar</Button>
                        : ''
                }

                <Button onClick={() =>salvePostPropertie()} className={'w-[180px] h-[36px]'}>
                    Postar
                </Button>
            </footer>
        </div>

    )
}