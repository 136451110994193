import {Delete, ImageAdd} from "../../Icons";
import {ChangeEvent} from "react";
interface IDocumentButton {
    icon: any;
    accept: string;
    classeName?: string;
    id: string;
    onSelect: any;
}
export default function DocumentButton({
                                           icon,
                                           accept,
                                           id,
                                           onSelect,
                                           classeName
}: IDocumentButton){

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        if (event.target.files === null) return;
        const files = Array.from(event.target.files);
        let base64Files: any[] = []
        /*files.map((file: any) => convertFileToBase64(file, (str: any) => {
            base64Files.push(str);
        }))*/

        onSelect(files)
        //if (onAddImage) onAddImage(files[0]);
        /*if (onAddImage) {
            convertFileToBase64(files[0], (str: any) => onAddImage(str))
            onAddImage(files[0]);
        }
        if (onAddMultiple) onAddMultiple(files);
        if (disableAutoPreview) return;
        if (!addMoreType && files[0] !== undefined) {
            reader.onload = () => {
                if (reader.result) {
                    setPreview(reader.result);
                }
            };

            reader.readAsDataURL(files[0]);
        }*/
    }
    return(
        <div className={classeName}>
            <label
                htmlFor={id}
                className="flex flex-col  gap-2 cursor-pointer"
            >
                {icon}
            </label>
            <input
                accept={accept}
                multiple={true}
                onChange={handleChange}
                id={id}
                type="file"
                className="hidden"
            />
        </div>
    )
}