import PageContainer from "../../container/PageContainerClient";
import React, {useState} from "react";
import Input from "../../inputs/Input";
import {CgSearch} from "react-icons/cg";
import SvgArrowLeft02StrokeRounded from "../../Icons/ArrowLeft02StrokeRounded";
import {useNavigate} from "react-router-dom";
import Str from "../../../helpers/Str";
import Button from "../../button/Button";
import Form from "../../form/Form";
import Alert from "../../../helpers/Alert";
export type IFormLayout = {
    className?: string;
    children: React.ReactNode;
    contentClassName?: string;
    title?: string;
    onSubmit?: () => void;
    urlReturn?: string;
    apiData?: any;
    state?: any;
    setState?: any;
    defaltValues?: any;
    sideMenu?: any;
    buttonLabel?: string;
    showButton?: boolean;
}
export default function FormLayout({
   children,
   title,
   urlReturn,
   className,
   onSubmit,
   apiData,
   state,
   setState,
   defaltValues,
   sideMenu,
   buttonLabel = "Enviar Mensagem",
   showButton = true,
}: IFormLayout){
    const [loadBtn, setLoadBtn] = useState(false);
    const navigate = useNavigate();

    async function onSubmitForm(){

        setLoadBtn(true);

        if (onSubmit) {
            onSubmit();
            setLoadBtn(false);
            return;
        }

        if (apiData) {
            const {data, isError} = await apiData.create(state);

            if (isError){
                if (typeof data === 'object') {
                    Object.keys(data).forEach(function(key, index) {
                        Alert.error(data[key][0]);
                    });
                    setLoadBtn(false);
                    return;
                }
                setLoadBtn(false);
                return Alert.error(data.message? data.message : data);
            }
            setState(defaltValues);
            setLoadBtn(false);

            Alert.success('Salvo com sucesso');

            setTimeout(function() {
                if (urlReturn) {
                    navigate(urlReturn);
                }
            }, 3000);
        }
    }

    return(
        <PageContainer>
            <div className={'flex md:flex-row xs:flex-col gap-3 xs:flex-col-reverse'}>
                <div className={Str.tw(className,`md:h-[calc(95vh_-_90px)] overflow-y-auto scrollbar border  border-system-gray-gray80 rounded-xl md:mb-[90px] bg-system-blue-medium ${sideMenu ? 'md:w-[80%]' : 'w-full'}`)}>
                    <Form className="h-full"  onSubmit={onSubmitForm}>
                        <head className={'p-5 border-b flex gap-4 items-center border-system-gray-gray80'}>
                            {urlReturn && (
                                <button type={'button'} onClick={() => navigate(urlReturn)}>
                                    <SvgArrowLeft02StrokeRounded width={20} />
                                </button>
                            )}
                            <div className={'text-xl font-bold'}>
                                {title}
                            </div>
                        </head>
                        <main className={''}>
                            {children}
                        </main>
                        <footer className={'sticky flex justify-end gap-4 p-5 border-t border-system-gray-gray80'}>
                            {showButton && (
                                <Button loading={loadBtn} type={'submit'} className={'w-[150px] whitespace-nowrap'}>
                                    {buttonLabel}
                                </Button>
                            )}
                        </footer>
                    </Form>
                </div>
                {
                    sideMenu ?
                        <div className={'h-[250px] md:w-[251px] border border-system-gray-gray80 rounded-xl bg-system-blue-medium'}>
                            <div className={'flex flex-col ml-5 gap-4 h-full justify-center'}>
                            {
                                sideMenu
                            }
                            </div>
                        </div>
                        :
                        ''
                }

            </div>

        </PageContainer>
    )
}