import React, { useState } from 'react';
import Str from '../../../helpers/Str';

export interface RatingProps {
    className?: string;
    label?: string;
    error?: string;
    value?: number;
    setValue?(value: number): void;
    disabled?: boolean;
    hideLabel?: boolean;
    name?: string;
    key?: string;
    required?: boolean;
}

export default function Rating({
    className,
    value = 0,
    label,
    error,
    setValue,
    hideLabel,
    name,
    key,
    required,
    ...rest
}: RatingProps) {

    const [hoveredStar, setHoveredStar] = useState<number | null>(null);

    function handleRatingChange(star: number): void {
        if (!setValue) return;
        setValue(star);
    }

    return (
        <label className={Str.tw('w-full', className)}>
            {!hideLabel && (
                <span>
                    {label} {(label && required) ? <span className='text-red-600'>*</span> : ''}
                </span>
            )}
            <div className="flex items-center" {...rest}>
                {[1, 2, 3, 4, 5].map((star) => (
                    <span
                        key={star}
                        onClick={() => handleRatingChange(star)}
                        onMouseEnter={() => setHoveredStar(star)}  // Define a estrela hovered
                        onMouseLeave={() => setHoveredStar(null)}
                        className={Str.tw(
                            'cursor-pointer text-8xl transition duration-300 ease-in-out',
                            star <= (hoveredStar !== null ? hoveredStar : value) ? 'text-yellow-500' : 'text-gray-300 hover:text-yellow-500',
                        )}
                    >
                        ★
                    </span>
                ))}
            </div>
            {!!error?.length && <span className="text-red-600">{error}</span>}
        </label>
    );
}
