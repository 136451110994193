import PageContainer from "../../../components/container/PageContainerClient";
import React, {useEffect, useState} from "react";
import CardPosts from "../../../components/CardPost";
import FullCalendarComponent from "../../../components/fullCalendar";
import postApi from "../../../services/postApi";
import {FaChevronDown, FaSearch} from "react-icons/fa";
import Button from "../../../components/button/Button";
import useModal from "../../../hooks/useModal";
import Modal from "../../../components/modal/Modal";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import noImage from "../../../assets/img/noImage.png";
import Str from "../../../helpers/Str";
import propertyApi from "../../../services/propertyApi";
export default function Home() {
    const [loading, setLoading] = useState(true);
    const [loadingPost, setLoadingPost] = useState(false);
    const [loadingProperties, setLoadingProperties] = useState(true);

    const [posts, setPosts]: any = useState([]);

    const [dateSelected, setDateSelected] = useState(/*new Date().toISOString().slice(0, 10)*/)
    const [dateConsult, setDateConsult] = useState(new Date());
    const [page, setPage] = useState(1);

    const [totalPage, setTotalPage] = useState();
    const[pageTo, setPageTo] = useState();
    const [dataModal, setDataModal]: any = useState();
    const { modalProps, open, close } = useModal();
    const [properties, setProperties] = useState([]);

    const [dataCalendar, setDataCalendar] = useState([])

    const [stepWorck, setStepWorck]: any = useState({
        earthworks_support: 5,
        gray_work: 60,
        finish: 35,
    });

    const getPostUser = async (date?: string | number, pageGet?: string | number) => {
        const endPage = pageGet ? pageGet : page
        const {data, isError} = await postApi.getPostsUser(date, endPage);
        if (!isError){
            if (data){
                setTotalPage(data.total)
                setPageTo(data.to)
                const result = Object.keys(data.data).map((key) => data.data[key]);
                setPosts(result)
            }

        }
        setLoading(false)
    }

    async function getProperties() {
        const {data, isError} = await propertyApi.getPropertyUser();
        if (!isError){
            setProperties(data)
        }
        setLoadingProperties(false)
    }

    const loadNewsPostUser = async () => {
        setLoadingPost(true);
        const newPage = page+1;
        const {data, isError} = await postApi.getPostsUser(dateSelected ? dateSelected : null, newPage);
        if (!isError){
            setTotalPage(data.total)
            setPageTo(data.to)
            const result = Object.keys(data.data).map((key) => data.data[key]);

            // @ts-ignore
            let countries = [].concat(posts, result);
            setPage(newPage);

            setPosts(countries)
        }
        setLoadingPost(false);
    }

    useEffect(() => {
        getProperties()
        getPostUser()
        getDataCalendar(new Date().toISOString().slice(0, 10))
    }, []);


    async function onClickDay(data: any) {
        if(data.dateStr){
            setDateSelected(data.dateStr);
            setPage(1)
            getPostUser(data.dateStr, 1)
        }
    }

    async function prev(){
        dateConsult.setDate(1);
        dateConsult.setMonth(dateConsult.getMonth() - 1);
        setDateConsult(dateConsult)
        getDataCalendar(dateConsult.toISOString().slice(0, 10))
    }

    async function next(){
        dateConsult.setDate(1);
        dateConsult.setMonth(dateConsult.getMonth() + 1);
        setDateConsult(dateConsult);
        getDataCalendar(dateConsult.toISOString().slice(0, 10))
    }


    async function getDataCalendar(date: string) {
        const {data, isError} = await postApi.getPostsUserDate(date);
        if(!isError){
            setDataCalendar(data)
        }

    }

    function openModal(data: any){
        setDataModal(data);
        open()
    }

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const onLikePost = async (id: string, isLiked: boolean) => {
        setLoadingPost(true);
        if (isLiked) {
            const {data, isError} = await postApi.unlikePost(id);
            if (!isError && data) {
                setPosts((prevPosts: any) =>
                    prevPosts.map((post: any) =>
                        post.id === id ? { ...post, user_liked: false } : post
                    )
                );
            }
        } else {
            const {data, isError} = await postApi.likePost(id);
            if (!isError && data) {
                setPosts((prevPosts: any) =>
                    prevPosts.map((post: any) =>
                        post.id === id ? { ...post, user_liked: true } : post
                    )
                );
            }
        }
        setLoadingPost(false);
    };

    return(
        <PageContainer>
            {
                loading ?
                    <span className="flex items-center justify-center h-7">
                        <svg className="animate-spin -ml-1 mr-3 h-[20px] w-[20px] text-border" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </span>
                    :
                    <div className={'flex md:flex-row xs:flex-col-reverse justify-center gap-4 md:mb-[4rem]'}>
                        <div className={'flex flex-col md:w-[700px] gap-3 mb-[20px]'}>

                            {

                                posts.length ?
                                    <>
                                        {

                                            posts.map((post: any) => {
                                                if (post.files){
                                                    return(
                                                        <CardPosts
                                                            post={post}
                                                            id={post.id}
                                                            description={post.description}
                                                            images={post.files.filter((file: any) => file.type == 'image')}
                                                            documents={post.files.filter((file: any) => file.type == 'file')}
                                                            dataCreation={post.created_at}
                                                            userCreate={post.user_create}
                                                            onClickImage={openModal}
                                                            isLiked={post.user_liked}
                                                            onLikePost={() => onLikePost(post.id, post.user_liked)}
                                                        />
                                                    )
                                                }else{
                                                    return(
                                                        <CardPosts
                                                            post={post}
                                                            id={post.id}
                                                            description={post.description}
                                                            images={[]}
                                                            documents={post.archives}
                                                            dataCreation={post.created_at}
                                                            userCreate={post.user_create}
                                                            onClickImage={openModal}
                                                            isLiked={post.user_liked}
                                                            onLikePost={() => onLikePost(post.id, post.user_liked)}
                                                        />
                                                    )
                                                }

                                            })
                                        }
                                        <div className={'w-full flex md:justify-end xs:justify-center mt-3 mb-3'}>
                                            {
                                                pageTo ?
                                                    totalPage != pageTo ?
                                                        <button
                                                            onClick={() => loadNewsPostUser()}
                                                            className={'border w-[50px] h-[50px] border-border border-2 items-center flex justify-center rounded-full'}>
                                                            {
                                                                loadingPost ?
                                                                    <span className="items-center flex justify-center h-7">
                                                                        <svg className="animate-spin  h-[20px] w-[20px] text-border" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                                        </svg>
                                                                    </span>
                                                                :
                                                                    <FaChevronDown/>
                                                            }
                                                        </button>
                                                        :
                                                        ''
                                                    :
                                                    ''
                                            }
                                        </div>
                                    </>
                                    :
                                    <div className={'flex border border-[#13131A] border-border items-center justify-center text-[20px] w-full p-3 h-[73px] rounded-xl bg-[#13131A]'}>
                                        Nenhuma atualização disponível
                                    </div>
                            }
                        </div>
                        <div className={'flex xs:justify-center'}>
                            <div className={'w-[400px]  flex flex-col gap-3'}>
                                <div className={'flex'}>
                                    <Button onClick={() => getPostUser()} className={'flex'}>Limpar filtros</Button>
                                </div>
                                <FullCalendarComponent
                                    functionNext={() => next()}
                                    functionPrev={() => prev()}
                                    calendarData={dataCalendar}
                                    onClickDay={(e: any) => onClickDay(e)}
                                />

                                {
                                    loadingProperties ?
                                        <span className="flex items-center justify-center h-7">
                                            <svg className="animate-spin -ml-1 mr-3 h-[20px] w-[20px] text-border" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                        </span>
                                        :

                                        properties.length ?
                                            properties.map((propery:any) => {

                                                const calc_earthworks_support = (stepWorck['earthworks_support']/100) * propery['earthworks_support'];
                                                const calc_gray_work = (stepWorck['gray_work']/100) * propery['gray_work'];
                                                const calc_finish = (stepWorck['finish']/100) * propery['finish'];

                                                const totalCalc = calc_earthworks_support + calc_gray_work + calc_finish
                                                return(
                                                    <div className={'flex flex-col bg-[#1C1C24] py-3 px-2 rounded-xl gap-3'}>
                                                        <div className={'flex justify-center text-[20px]'}>
                                                            Status da obra
                                                        </div>
                                                        <div className={'flex justify-center text-[20px]'}>
                                                            {propery.description}
                                                        </div>
                                                        <div>
                                                            <div className={'flex justify-center text-[18px]'}>
                                                                Terraplanagem e Arrimo
                                                            </div>
                                                            <div className={'flex items-center gap-2'}>
                                                                <div className={`h-[15px] w-full bg-neutral-200 dark:bg-neutral-600 rounded-lg`}>
                                                                    <div className={`h-[15px] rounded-lg `} style={{width: propery['earthworks_support']+'%', backgroundColor:'#E0A422'}}/>
                                                                </div>
                                                                <div className={'text-[18px]'}>
                                                                    {propery['earthworks_support']+'%'}
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className={'flex justify-center text-[18px]'}>
                                                                Obra Cinza
                                                            </div>
                                                            <div className={'flex items-center gap-2'}>
                                                                <div className={`h-[15px] w-full bg-neutral-200 dark:bg-neutral-600 rounded-lg`}>
                                                                    <div className={`h-[15px] rounded-lg `} style={{width: propery['gray_work']+'%', backgroundColor:'#E0A422'}}/>
                                                                </div>
                                                                {propery['gray_work']+'%'}
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div className={'flex justify-center text-[18px]'}>
                                                                Acabamento
                                                            </div>
                                                            <div className={'flex items-center gap-2'}>
                                                                <div className={`h-[15px] w-full bg-neutral-200 dark:bg-neutral-600 rounded-lg`}>
                                                                    <div className={`h-[15px] rounded-lg `} style={{width: propery['finish']+'%', backgroundColor:'#E0A422'}}/>
                                                                </div>
                                                                {propery['finish']+'%'}
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div className={'flex justify-center text-[18px]'}>
                                                                Total geral da obra
                                                            </div>
                                                            <div className={'flex items-center gap-2'}>
                                                                <div className={`h-[15px] w-full bg-neutral-200 dark:bg-neutral-600 rounded-lg`}>
                                                                    <div className={`h-[15px] rounded-lg `} style={{width: totalCalc+'%', backgroundColor:'#4EF970'}}/>
                                                                </div>
                                                                {totalCalc+'%'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })


                                        :
                                        ''
                                }




                            </div>
                        </div>

                    </div>

            }
            <Modal
                size={'mdm'}
                footer={false}
                {...modalProps}
            >
                {
                    dataModal ?
                        <div className={'bg-[#13131A] rounded-xl'}>
                            <div className="flex items-center justify-between p-4 ">
                                <div>
                                    <div className={'flex gap-3 items-center'}>
                                        <div>
                                            <img className={'w-[50px] h-[50px] rounded-full '} src={dataModal.photo_path ? dataModal.photo_path : noImage}/>
                                        </div>
                                        <div >
                                            <div className={'text-[#FAFAFB] text-[20px]'}>
                                                {dataModal.user_create.name}
                                            </div>
                                            <div className={'text-[#696974] text-[14px]'}>
                                                {Str.convertDateStringComplet(dataModal.created_at)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    onClick={close}
                                    type="button"
                                    className="transition text-system-primary rounded-full p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-light focus:outline-none focus:text-gray-600"
                                >
                                    <span className="sr-only">Close menu</span>
                                    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                            <div>
                                <Carousel
                                    responsive={responsive}
                                    infinite={dataModal.files.length > 1}
                                    autoPlay
                                    transitionDuration={2000}
                                    showDots={true}
                                >
                                {
                                    dataModal.files.map((file: any) => {
                                        if(file.type == 'image'){
                                            return(
                                                <div className={'w-full flex p-3 gap-2 justify-center items-center '}>
                                                    <img
                                                        className={'h-[450px] '}
                                                        src={file.url_path}
                                                    />
                                                </div>
                                            )
                                        }
                                    })
                                }
                                </Carousel>
                            </div>
                            <div className={'text-[16px] text-[#B5B5BE] p-4'}>
                                {dataModal.description}
                            </div>
                        </div>
                        :
                        ''
                }

            </Modal>
        </PageContainer>
    )
}