import Navbar from "../NavBarClient";

export interface IPageContainerClient {
    children: React.ReactNode;
}

export default function PageContainerClient({children}: IPageContainerClient) {
    return(
        <div className={' md:overflow-hidden bg-system-blue-dark '}>
            <Navbar/>
            <div className={'flex w-full md:h-full overflow-hidden justify-center items-center'}>
                <div className={'md:w-container xs:w-full  px-3 pt-5 max-h-full overflow-auto text-[#B5B5BE] scrollbar'}>
                    {children}
                </div>
            </div>
        </div>
    )
}