import React, {useEffect, useState} from "react";
import Input from "../../../components/inputs/Input";
import {BsPlus} from "react-icons/bs";
import {IoIosClose} from "react-icons/io";
import InputSelect from "../../../components/inputSelect";
import {Settings05} from "../../../components/Icons";
import useModal from "../../../hooks/useModal";
import Modal from "../../../components/modal/Modal";
import {Checkbox} from "../../../components/inputs/Checkbox";
import rolesApi from "../../../services/rolesApi";
import Alert from "../../../helpers/Alert";

interface IPermissionsRole {
    field_text?: any;
    id?: number | string | never;
    name?: string;
    children?: any;
}
export function AccessProfile({permissionEdit}: any) {
    const [state, setState]: any = useState([]);
    const [loading, setLoading] = useState(true);
    const [permissions, setPermissions] = useState([]);
    const [permissionsRole, setPermissionsRole] = useState([]);
    const [roles, setRoles] = useState([]);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [roleIndexData, setRoleIndexData] = useState(0);

    const { modalProps, open, close } = useModal();

    useEffect(() => {
        getData();
    }, [])


    const getData = async () => {
        const [permissionsData, rolesData] = await Promise.all([rolesApi.getAllPermissions(), rolesApi.getAllRolesWithPermitions()]);
        if (!permissionsData.isError){
            setPermissions(permissionsData.data)
        }
        if (!rolesData.isError){
            setRoles(rolesData.data)
        }
        setLoading(false);
    }

    async function onSubmitPermission(){
        setLoadingBtn(true)
        const newRoles = [...roles];
        state['permissions'] = permissionsRole;
        const {data, isError} = await rolesApi.updateRole(state.id, state);
        if (!isError){
            // @ts-ignore
            newRoles[roleIndexData].permissions = data.permissions
            setRoles(newRoles);
            Alert.success("Permissões atualizadas!")
        }
        setLoadingBtn(false)
    }

    async function onSubmitRole(dataForm: any, index: number){
        const formData = {
            name: dataForm.name,
            guard_name: "users"
        }
        const {data, isError} = dataForm.id
            ? await rolesApi.updateRole(dataForm.id, formData)
            : await rolesApi.createRole(formData);

        const newRoles = [...roles]
        // @ts-ignore
        newRoles[index]['id'] = data.id;
        setRoles(newRoles);
    }

    const openModal = (role: any, roleIndex: number) => {
        setRoleIndexData(roleIndex);
        setState(role);
        setPermissionsRole(role.permissions.map((permission: any) => permission.id));
        open()
    }

    const checkPermissions = (value: any) => {
        //filtra a permissão para validar o filho dela
        const permissionsFiler = permissions.filter((per:any) => per.id === value);

        // @ts-ignore
        if (permissionsRole.includes(value)){
            let newPermissions = permissionsRole.filter((permission: any) => permission !== value);
            if (permissionsFiler.length){
                // @ts-ignore
                if (permissionsFiler[0]['children'].length){
                    //filtra o filho da permissão para remover do check
                    newPermissions = newPermissions.filter((permission: any) => permission !== permissionsFiler[0]['children'][0]['id']);
                }
            }
            setPermissionsRole(newPermissions);
        }else{
            const newPermissionsRole = [...permissionsRole];

            if (permissionsFiler.length){
                // @ts-ignore
                if (permissionsFiler[0]['children'].length){
                    newPermissionsRole.push(permissionsFiler[0]['children'][0]['id'])
                }
            }
            // @ts-ignore
            newPermissionsRole.push(value)
            setPermissionsRole(newPermissionsRole)
        }
    }

    const handleChange = (value: any, input: string, index: number) => {
        const newRoles = [...roles]
        // @ts-ignore
        newRoles[index][input] = value;
        setRoles(newRoles);
    };

    const addData = () => {
        const newRoles = [...roles]
        const newData = {
            guard_name: "users",
            id: '',
            name: '',
            permissions: [],
        }
        // @ts-ignore
        newRoles.push(newData);
        setRoles(newRoles);
    }

    async function onDelete(dataRole: any, index: number) {
        const confirm = await Alert.confirm(
            'Tem certeza que deseja excluir?',
            `Excluir esse dado?`
        );
        if (! confirm) return;

        const {data, isError} = await rolesApi.deleteRole(dataRole.id);
        if (!isError){
            const newRoles = [...roles]

            setRoles(newRoles.filter((rol: any, indexFilter) => indexFilter != index));

            Alert.success('Deletado com sucesso!')
        }

    }

    return(
        <>
            {
                loading ?
                    <span className="flex items-center justify-center h-20 p-5">
                      <svg className="animate-spin -ml-1 mr-3 h-[30px] w-[30px] text-system-primary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </span>
                :
                    <div className={'p-4'} >
                        {
                            roles ?
                                roles.map((role: any, index) => {
                                    return (
                                        <div className="grid grid-cols-12 gap-5 mt-4">
                                            <Input
                                                large
                                                value={role.name}
                                                setValue={(v) => handleChange(v, 'name', index)}
                                                OnBlur={() => onSubmitRole(role, index)}
                                                className={'md:col-span-7 xs:col-span-12'}
                                                label={'Descrição'}

                                            />
                                            <div className={'h-full gap-3 flex md:col-span-3 xs:col-span-3 items-center mt-2'}>
                                                <button disabled={!permissionEdit} onClick={addData} className={'bg-system-blue-dark  md:w-[29px] md:h-[29px] rounded-full flex justify-center items-center hover:bg-system-primary hover:text-white'}>
                                                    <BsPlus/>
                                                </button>
                                                <button disabled={!permissionEdit} onClick={() => onDelete(role, index)} className={'bg-system-blue-dark  md:w-[29px] md:h-[29px] rounded-full flex justify-center items-center hover:border-system-red hover:bg-system-red hover:text-white '}>
                                                    <IoIosClose/>
                                                </button>
                                                <button disabled={!permissionEdit} onClick={() => openModal(role, index)} className={'md:w-[29px] md:h-[29px] rounded-full flex justify-center items-center text-[20px] text-system-primary '}>
                                                    <Settings05/>
                                                </button>
                                            </div>
                                        </div>
                                    )

                                })
                                : ''
                        }
                    </div>
            }
            <Modal
                size="mds"
                title={`Definir permissão`}
                footer={true}
                onClick={() => onSubmitPermission()}
                {...modalProps}
            >
                <div className={'gap-3 p-3'}>
                {
                    permissions.map((permission: IPermissionsRole) => {

                        // @ts-ignore
                        const value = permission.id ? permissionsRole.includes(permission.id) : false;

                        // @ts-ignore
                        const valueChieldren = permission.children.length ? permissionsRole.includes(permission.children[0].id) : false;

                        return(
                            <div className={'flex mt-3 gap-3'}>
                                <label className={'flex'}>
                                    <Checkbox
                                        value={value}
                                        className="col-span"
                                        setValue={(v) => checkPermissions(permission.id)}
                                    />
                                    {permission.field_text}
                                </label>
                                {
                                    permission.children.length ?
                                        <>
                                            -
                                            <label className={'flex'}>
                                                <Checkbox
                                                    value={valueChieldren}
                                                    className="col-span"
                                                    setValue={(v) => checkPermissions(permission.children[0].id)}
                                                />
                                                Editar
                                            </label>
                                        </>
                                        :
                                        ''
                                }


                            </div>
                        )
                    })
                }
                </div>

            </Modal>
        </>
    )
}