import noImage from "../../assets/img/noImage.png";
import { HiDotsHorizontal } from "react-icons/hi";
import React from "react";
import Str from "../../helpers/Str";
import { IoClose } from "react-icons/io5";

interface ICardNotification {
    description?: string;
    dataCreation?: string;
    id: number;
}

export default function CardNotification({description, dataCreation, id}: ICardNotification) {
    return (
        <div className="rounded-xl w-full bg-[#1C1C24] p-5">
            <div className="flex justify-between">
                <div className="flex gap-3 items-center">
                    <div>
                        <div className="text-[#696974] text-[14px]">
                            {Str.convertDateStringComplet(dataCreation)}
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-4 text-[16px] text-[#B5B5BE]">
                {description}
            </div>
        </div>
    );
}
