import PageContainer from "../../../components/container/PageContainerClient";
import React, {useEffect, useState} from "react";
import propertyApi from "../../../services/propertyApi";
import CardProperty from "../../../components/CardProperty";

export default function Projects() {
    const [loading, setLoading] = useState(true);
    const [properties, setProperties] = useState([]);

    useEffect(() => {
        getProperties()
    }, []);
    async function getProperties() {
        const {data, isError} = await propertyApi.getPropertyUser(1);
        if (!isError){
            setProperties(data)
        }
        setLoading(false)
    }

    return(
        <PageContainer>
            {
                loading ?
                    <span className="flex items-center justify-center h-7">
                        <svg className="animate-spin -ml-1 mr-3 h-[20px] w-[20px] text-border" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </span>
                    :
                    <div className={'flex md:flex-row xs:flex-col-reverse justify-center gap-4'}>
                        <div className={'flex flex-col md:w-[700px] gap-3'}>
                            {
                                properties.length ?
                                    <>
                                        {
                                            properties.map((property: any) => {
                                                return(
                                                    <CardProperty
                                                        property={property}
                                                    />
                                                )
                                            })
                                        }
                                    </>
                                    :
                                    <div className={'flex border border-[#13131A] border-border items-center justify-center text-[20px] w-full p-3 h-[73px] rounded-xl bg-[#13131A]'}>
                                        Nenhum imóvel vinculado
                                    </div>

                            }
                        </div>
                    </div>
            }
        </PageContainer>
    )
}