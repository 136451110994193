import contactApi from "../../../services/contactApi";
import FormLayout from "../../../components/layout/FormLayoutClient";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import Input from "../../../components/inputs/Input";
import InputTextArea from "../../../components/inputs/InputTextArea";
import InputSelect from "../../../components/inputSelect";
import {useNavigate} from "react-router-dom";

export default function FormContact() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const defaltValues = {
        name: '',
        email: '',
        phone: '',
        subject: '',
        contact_type: '',
        message: '',
    }
    const [state, setState]: any = useState(defaltValues);

    const handleChange = (value: any, input: string) => {
        const newState = {...state}
        const inputValuesArray = input.split('.');
        if (inputValuesArray.length > 1) {
            newState[inputValuesArray[0]][inputValuesArray[1]] = value
        }else{
            newState[input] = value
        }

        if (!state.id){
            if (input === 'cpf'){
                newState['password'] = value
            }
        }
        setState(newState);
    };

    const statusOptions = [
        { value: 1, label: 'Elogio' },
        { value: 2, label: 'Reclamação' },
        { value: 3, label: 'Dúvida' },
        { value: 4, label: 'Outros' },
    ];

    return(
        <>
            <FormLayout
                title={'Será um prazer lhe atender nas suas dúvidas ou elogios. Fale conosco e em breve retornaremos.'}
                state={state}
                setState={setState}
                apiData={contactApi}
                defaltValues={defaltValues}
                sideMenu={
                    <>
                        <div className="flex gap-2 items-center text-[14px]">
                            <i className="fas fa-map-marker-alt text-[22px] text-system-primary" />
                            <div>
                                <p className="font-bold">Localização</p>
                                <p>Av. 136, nº 214, St. Marista</p>
                                <p>Goiânia - GO, 74180-040</p>
                            </div>
                        </div>

                        <div className="flex gap-2 items-center text-[14px] mt-4">
                            <i className="fas fa-phone-alt text-[22px] text-system-primary" />
                            <div>
                                <p className="font-bold">Telefone / WhatsApp</p>
                                <p>(62) 99293-1893</p>
                            </div>
                        </div>

                        <div className="flex gap-2 items-center text-[14px] mt-4">
                            <i className="fas fa-envelope text-[22px] text-system-primary" />
                            <div>
                                <p className="font-bold">E-mail</p>
                                <p>administrativo@casasmh.com.br</p>
                            </div>
                        </div>
                    </>
                }
            >
                {
                    loading ?
                        <span className="flex items-center justify-center h-7">
                            <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </span>
                        :
                        <>
                            <div className={'flex  w-full '}>
                                <div className={'sm:w-full grid p-3'}>
                                    <div className="grid grid-cols-12 gap-3">
                                        <Input
                                            label={'Nome'}
                                            className={'md:col-span-12 xs:col-span-12'}
                                            value={state.name}
                                            required
                                            setValue={(v) => handleChange(v, 'name')}
                                        />
                                    </div>
                                    <div className="mt-3 grid grid-cols-12 gap-3">
                                        <Input
                                            label={'E-mail'}
                                            value={state.email}
                                            required
                                            className={'md:col-span-8 xs:col-span-12'}
                                            inputType={'email'}
                                            setValue={(v) => handleChange(v, 'email')}
                                        />
                                        <Input
                                            label={'Telefone'}
                                            value={state.phone}
                                            required
                                            className={'md:col-span-4 xs:col-span-12'}
                                            mask={'cellphone'}
                                            setValue={(v) => handleChange(v, 'phone')}
                                        />
                                    </div>
                                    <div className="mt-3 grid grid-cols-12 gap-3">
                                        <Input
                                            label={'Assunto'}
                                            value={state.subject}
                                            required
                                            className={'md:col-span-8 xs:col-span-12'}
                                            setValue={(v) => handleChange(v, 'subject')}
                                        />
                                        <InputSelect
                                            key={(Math.random() + 1).toString(36).substring(7)}
                                            selected={state.contact_type}
                                            label={'Tipo de contato'}
                                            required
                                            className={'md:col-span-4 xs:col-span-12'}
                                            options={statusOptions}
                                            onChangeSelect={(e: any) => handleChange(e.value, 'contact_type')}
                                        />
                                    </div>
                                    <div className="mt-3 grid grid-cols-12 gap-3">
                                        <InputTextArea
                                            label={'Mensagem'}
                                            value={state.message}
                                            required
                                            rows={4}
                                            className={'md:col-span-12 xs:col-span-12'}
                                            setValue={(v) => handleChange(v, 'message')}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={'border-b border-system-gray-gray80'}></div>

                        </>
                }
            </FormLayout>
        </>
    )
}