export interface MenuClient {
    title: string;
    path?: string;
    subMenus?: MenuClient[];
}

export default [
    {
        title: 'Home',
        path: '/cliente/home',
    },
    {
        title: 'Minhas Obras',
        path: '/cliente/obras',
    },
    {
        title: 'Meus Investimentos',
        path: '/cliente/investimento',
    },
    {
        title: 'Fale Conosco',
        path: '/cliente/faleconosco',
    },
];
