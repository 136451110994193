import * as React from "react";
import type { SVGProps } from "react";
const SvgPdf = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '1em'}
    height={props.height || '1em'}
    viewBox="0 0 20 22"
    {...props}
  >
    <g
      fill="none"
      stroke="CurrentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    >
      <path
        d="M5 17v-2.5m0 0V13c0-.471 0-.707.154-.854s.4-.146.9-.146h.7a1.251 1.251 0 1 1 0 2.5ZM19 12h-1.312c-.825 0-1.237 0-1.494.244s-.256.637-.256 1.423v.833m0 2.5v-2.5m0 0h2.188m-4.375 0a2.565 2.565 0 0 1-2.626 2.5 1.36 1.36 0 0 1-.613-.067c-.292-.16-.262-.485-.262-.766v-3.334c0-.281-.029-.606.262-.766a1.36 1.36 0 0 1 .613-.067 2.565 2.565 0 0 1 2.625 2.5Z"
        data-name="Caminho 377"
      />
      <path
        d="M13 21H8.727c-3.261 0-4.892 0-6.024-.8a4.1 4.1 0 0 1-.855-.805C1 18.331 1 16.8 1 13.727v-2.545c0-2.963 0-4.445.469-5.628a7.23 7.23 0 0 1 4.369-4.113C7.1 1 8.67 1 11.818 1a11.3 11.3 0 0 1 3.417.252 4.13 4.13 0 0 1 2.5 2.35A9.4 9.4 0 0 1 18 6.818V9"
        data-name="Caminho 378"
      />
      <path
        d="M1 11a3.333 3.333 0 0 1 3.333-3.333 8.5 8.5 0 0 0 2.1-.057A1.67 1.67 0 0 0 7.61 6.431a8.5 8.5 0 0 0 .057-2.1A3.333 3.333 0 0 1 11 1"
        data-name="Caminho 379"
      />
    </g>
  </svg>
);
export default SvgPdf;
