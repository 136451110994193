import React from 'react';
import { AppRoute } from "./index";
import Login from "../pages/Auth/Login";
import NewUserOff from "../pages/NewUserOff";
import Dashboard from "../pages/Dashboard";
import Home from "../pages/Home";
import Client from "../pages/Clint";
import FormClient from "../pages/Clint/Form";
import Users from "../pages/Users";
import FormUsers from "../pages/Users/Form";
import Properties from "../pages/Properties";
import FormProprieties from "../pages/Properties/Form";
import Files from "../pages/Files";
import FormFiles from "../pages/Files/Form";
import Config from "../pages/Config";
import Message from "../pages/Message";
import Notification from "../pages/Notification";
import ListProperties from "../pages/Clint/ListProperties";
import Report from "../pages/Report";
import ListPostsProperties from "../pages/Properties/ListPostsProperties";
import ListPropertiesFiles from "../pages/Files/ListPropertiesFiles";
import ClientHome from "../pages/Client/Home";
import ClientProjects from "../pages/Client/Projects";
import ClientInvestment from "../pages/Client/Investment";
import ClientContact from "../pages/Client/Contact";
import ClientMessage from '../pages/Client/Message';
import FormClientMessage from '../pages/Client/Message/Form';
import FormAdminMessage from '../pages/Message/Form';
import ClientNotification from '../pages/Client/Notification';
import FoldersFiles from "../pages/FolderFiles";
import FormFolders from "../pages/FolderFiles/Form"

const appRoutes: AppRoute[] =  [
    /*{
        path: '/',
        requiresAuth: false,
        element: <LandinPage />
    },*/
    {
        path: '/',
        requiresAuth: false,
        element: <Login />
    },
    {
        path: '/home',
        requiresAuth: true,
        element: <Home/>
    },
    {
        path: '/dashboard/:tab',
        requiresAuth: true,
        element: <Dashboard />
    },
    {
        path: '/dashboard',
        requiresAuth: true,
        element: <Dashboard />
    },
    {
        path: '/clientes',
        requiresAuth: true,
        element: <Client />
    },
    {
        path: '/clientes/formulario',
        requiresAuth: true,
        element: <FormClient />
    },
    {
        path: '/clientes/formulario/:id',
        requiresAuth: true,
        element: <FormClient />
    },


    {
        path: '/usuarios',
        requiresAuth: true,
        element: <Users />
    },
    {
        path: '/usuarios/formulario',
        requiresAuth: true,
        element: <FormUsers />
    },
    {
        path: '/usuarios/formulario/:id',
        requiresAuth: true,
        element: <FormUsers />
    },
    {
        path: '/cliente/:id/propriedades',
        requiresAuth: true,
        element: <ListProperties />
    },


    {
        path: '/propriedades',
        requiresAuth: true,
        element: <Properties />
    },
    {
        path: '/propriedades/formulario',
        requiresAuth: true,
        element: <FormProprieties />
    },
    {
        path: '/propriedades/formulario/:id',
        requiresAuth: true,
        element: <FormProprieties />
    },
    {
        path: '/propriedades/:id/posts',
        requiresAuth: true,
        element: <ListPostsProperties />
    },
    {
        path: '/pastas',
        requiresAuth: true,
        element: <FoldersFiles />
    },
    {
        path: '/pastas/formulario',
        requiresAuth: true,
        element: <FormFolders />
    },
    {
        path: '/pastas/:folderId/arquivos',
        requiresAuth: true,
        element: <Files />
    },
    {
        path: '/pastas/:folderId/arquivos/formulario',
        requiresAuth: true,
        element: <FormFiles />
    },
    {
        path: '/pastas/:folderId/arquivos/formulario/:id',
        requiresAuth: true,
        element: <FormFiles />
    },
    {
        path: '/pastas/:folderId/arquivos/:id/propriedades',
        requiresAuth: true,
        element: <ListPropertiesFiles />
    },

    {
        path: '/configuracoes',
        requiresAuth: true,
        element: <Config />
    },

    {
        path: '/mensagens',
        requiresAuth: true,
        element: <Message />
    },
    {
        path: '/mensagens/:tab',
        requiresAuth: true,
        element: <Message />
    },
    {
        path: '/mensagem/:id',
        requiresAuth: true,
        element: <FormAdminMessage />
    },

    {
        path: '/notificacao',
        requiresAuth: true,
        element: <Notification />
    },

    {
        path: '/relatorios',
        requiresAuth: true,
        element: <Report />
    },


    {
        path: '/cliente/home',
        requiresAuth: true,
        element: <ClientHome />
    },
    {
        path: '/cliente/obras',
        requiresAuth: true,
        element: <ClientProjects />
    },
    {
        path: '/cliente/investimento',
        requiresAuth: true,
        element: <ClientInvestment />
    },
    {
        path: '/cliente/faleconosco',
        requiresAuth: true,
        element: <ClientContact />
    },
    {
        path: '/cliente/mensagens',
        requiresAuth: true,
        element: <ClientMessage />
    },
    {
        path: '/cliente/mensagens/:id',
        requiresAuth: true,
        element: <FormClientMessage />
    },
    {
        path: '/cliente/notificacao',
        requiresAuth: true,
        element: <ClientNotification />
    },



    {
        path: '/*',
        requiresAuth: false,
        element: <h1>404</h1>
    }
];

export default appRoutes;