import userApi from "../../../services/userApi";
import FormLayout from "../../../components/layout/FormLayout";
import React, {useEffect, useState} from "react";
import {DocumentAttachment, SearchSquare} from "../../../components/Icons";
import {useParams} from "react-router";
import Input from "../../../components/inputs/Input";
import InputImage from "../../../components/inputs/InputImage";
import ViaCepService from "../../../services/viaCepApi";
import InputSelect from "../../../components/inputSelect";
import propertyApi from "../../../services/propertyApi";
import Modal from "../../../components/modal/Modal";
import useModal from "../../../hooks/useModal";
import Alert from "../../../helpers/Alert";
import clientApi from "../../../services/clientApi";
import {useNavigate} from "react-router-dom";
import Storage from "../../../helpers/Storage";

export default function FormClient() {
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [propertiesOptions, setPropertiesOptions]: any = useState([]);
    const cepSrv = new ViaCepService();
    const defaltValues = {
        id: '',
        cpf: '',
        name: '',
        date_birth: '',
        email: '',
        phone: '',
        status: 1,
        role: 3,
        type: 'cliente',
        image: '',
        access_profile: '',
        properties: [],
        address: {
            cep: '',
            address: '',
            number: '',
            block: '',
            lot: '',
            complement: '',
            neighborhood: '',
            city: '',
            state: '',
        }
    }
    const [state, setState]: any = useState(defaltValues);
    const { modalProps, open, close } = useModal();

    const handleChange = (value: any, input: string) => {
        const newState = {...state}
        const inputValuesArray = input.split('.');
        if (inputValuesArray.length > 1) {
            newState[inputValuesArray[0]][inputValuesArray[1]] = value
        }else{
            newState[input] = value
        }

        if (!state.id){
            if (input === 'cpf'){
                newState['password'] = value
            }
        }
        setState(newState);
    };

    const handleChangeCep = (value: string) => {
        if (state.address.cep.length === 8) {
            cepSrv.consultaCep(state.address.cep).then((cepResponse: any) => {
                if (!cepResponse.data.erro) {
                    const newState = {...state};
                    newState.address.address = cepResponse.data.logradouro;
                    newState.address.neighborhood = cepResponse.data.bairro;
                    newState.address.city = cepResponse.data.localidade;
                    newState.address.state = cepResponse.data.uf;
                    setState(newState);
                }
            });
        }
    }

    const onRemoveImage = () => {
        const newState = {...state};
        newState.image = '';
        setState(newState);
    }

    useEffect(() => {
        getProperties();
        if (params.id){
            getData()
        }else{
            setLoading(false)
        }
    }, [])

    const getData = async () => {
        setLoading(true);
        // @ts-ignore
        const {data, isError} = await userApi.one(params.id.toString(), 'relations=properties');

        setState(
            {
                id: data.id,
                cpf: data.cpf,
                name: data.name,
                date_birth: data.date_birth,
                email: data.email,
                phone: data.phone,
                status: data.status ? 1 : 0,
                role: data.roles.length ? data.roles[0].id : '',
                type: 'cliente',
                image: data.photo_path,
                access_profile: data.access_profile,
                address: data.address,
                newProperies: data.properties.map((property: any) => property.id)
            }
        )
        setLoading(false);
    }

    const getProperties = async () => {
        const {data, isError} = await propertyApi.listAll();
        if (!isError){
            const opt = data.map((property: any) => {
                return { value: property.id, label: property.description }
            });
            setPropertiesOptions(opt)
        }
    }

    const statusOptions = [
        { value: 1, label: 'Ativo' },
        { value: 0, label: 'Inativo' },
    ];

    const accessProfileOptions = [
        { value: 'My Hunter', label: 'My Hunter' },
        { value: 'Casas MH', label: 'Casas MH' },
        { value: 'My Hunter / Casas MH', label: 'My Hunter / Casas MH' },
    ];

    const onClickModal = async () => {
        const dataForm = {
            properties: state.properties
        }
        if (state.id) {
            const confirm = await Alert.confirm(
                'Tem certeza que deseja realizar esse vínculo?',
                `Vincular Cliente?`
            );
            if (!confirm) return;
            const {data, isError} = await clientApi.update(state.id, dataForm);

            if (!isError) {
                const newState = {...state}
                newState.properties = []
                setState(newState);
                Alert.success('Vinculado com sucesso!')
                close()
            }
        } else {
            close()
        }
    }

    const permissions: any = Storage.getUserPermissions();
    const editValidation = permissions?.includes('client.edit');

    return(
        <>
            <FormLayout
                title={'Cadastro de Cliente'}
                urlReturn={'/clientes'}
                state={state}
                setState={setState}
                apiData={userApi}
                defaltValues={defaltValues}
                functionNewData={onRemoveImage}
                permission={'client.edit'}
                sideMenu={
                    <>
                        <button disabled={loading || !editValidation} type={'button'} onClick={() => open()} className={'flex gap-2 items-center text-[14px] hover:text-system-primary'}>
                            <DocumentAttachment className={'text-[22px]'}/>Vincular Imóvel
                        </button>

                        <button onClick={() => params.id ? navigate(`/cliente/${params.id}/propriedades`) : console.log('')} disabled={loading} className={'flex gap-2 items-center text-[14px] hover:text-system-primary'}>
                            <SearchSquare className={'text-[22px]'}/>Ver Imóveis Vinculados
                        </button>
                    </>
                }
            >
                {
                    loading ?
                        <span className="flex items-center justify-center h-7">
                            <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </span>
                        :
                        <>
                            <div className={'flex  w-full '}>
                                <div className={'sm:w-full grid p-3'}>
                                    <div className="grid grid-cols-12 gap-3">
                                        <Input
                                            label={'CPF'}
                                            className={'md:col-span-3 xs:col-span-12'}
                                            mask={'CPF'}
                                            value={state.cpf}
                                            required
                                            setValue={(v) => handleChange(v, 'cpf')}
                                        />
                                        <Input
                                            label={'Nome'}
                                            value={state.name}
                                            required
                                            className={'md:col-span-5 xs:col-span-12'}
                                            setValue={(v) => handleChange(v, 'name')}
                                        />
                                        <Input
                                            label={'Data de nascimento'}
                                            value={state.date_birth}
                                            type={'date'}
                                            required
                                            className={'md:col-span-3 xs:col-span-12'}
                                            setValue={(v) => handleChange(v, 'date_birth')}
                                        />
                                    </div>
                                    <div className="mt-3 grid grid-cols-12 gap-3">
                                        <Input
                                            label={'E-mail'}
                                            value={state.email}
                                            required
                                            className={'md:col-span-5 xs:col-span-12'}
                                            inputType={'email'}
                                            setValue={(v) => handleChange(v, 'email')}
                                        />
                                        <Input
                                            label={'Telefone'}
                                            value={state.phone}
                                            className={'md:col-span-3 xs:col-span-12'}
                                            mask={'cellphone'}
                                            setValue={(v) => handleChange(v, 'phone')}
                                        />

                                        <div className={'col-span-4 flex items-center md:p-3 md:ml-3 md:gap-2'}>
                                            <InputImage
                                                /*onAddImage={value => setImage(value)}*/
                                                urlPrevew={state.image}
                                                onAddImage={value => setState({...state, image: value})}
                                                onRemoveImage={() => onRemoveImage()}
                                            />

                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className={'border-b border-system-gray-gray80'}></div>

                            <div className={'sm:w-full grid p-3'}>
                                <div className="grid grid-cols-12 gap-3">
                                    <Input
                                        required
                                        label={'CEP'}
                                        inputType={'CEP'}
                                        value={state.address.cep}
                                        setValue={(v) => handleChange(v, 'address.cep')}
                                        OnBlur={(e: any) => handleChangeCep(e.target.value)}
                                        className={'md:col-span-2 xs:col-span-12'}
                                    />
                                    <Input
                                        required
                                        label={'Endereço'}
                                        value={state.address.address}
                                        className={'md:col-span-4 xs:col-span-12'}
                                        setValue={(v) => handleChange(v, 'address.address')}
                                    />
                                    <Input
                                        label={'N'}
                                        value={state.address.number}
                                        className={'md:col-span-1 xs:col-span-12'}
                                        setValue={(v) => handleChange(v, 'address.number')}
                                    />
                                    <Input
                                        label={'Qd'}
                                        value={state.address.block}
                                        className={'md:col-span-1 xs:col-span-12'}
                                        setValue={(v) => handleChange(v, 'address.block')}
                                    />
                                    <Input
                                        label={'Lote'}
                                        value={state.address.lot}
                                        className={'md:col-span-1 xs:col-span-12'}
                                        setValue={(v) => handleChange(v, 'address.lot')}
                                    />
                                    <Input
                                        label={'Complemento'}
                                        value={state.address.complement}
                                        className={'md:col-span-3 xs:col-span-12'}
                                        setValue={(v) => handleChange(v, 'address.complement')}
                                    />
                                </div>
                                <div className="grid grid-cols-12 gap-3 mt-5">
                                    <Input
                                        required
                                        label={'Setor'}
                                        value={state.address.neighborhood}
                                        className={'md:col-span-6 xs:col-span-12'}
                                        setValue={(v) => handleChange(v, 'address.neighborhood')}
                                    />

                                    <Input
                                        required
                                        label={'Estado'}
                                        value={state.address.state}
                                        className={'md:col-span-3 xs:col-span-12'}
                                        setValue={(v) => handleChange(v, 'address.state')}
                                    />
                                    <Input
                                        required
                                        label={'Cidade'}
                                        value={state.address.city}
                                        className={'md:col-span-3 xs:col-span-12'}
                                        setValue={(v) => handleChange(v, 'address.city')}
                                    />
                                </div>
                            </div>

                            <div className={'border-b border-system-gray-gray80'}></div>

                            <div className={'sm:w-full grid p-3'}>
                                <div className="grid grid-cols-12 gap-3">
                                    <InputSelect
                                        key={(Math.random() + 1).toString(36).substring(7)}
                                        selected={state.access_profile}
                                        required
                                        label={'Perfil de acesso'}
                                        className={'md:col-span-3 xs:col-span-12'}
                                        options={accessProfileOptions}
                                        onChangeSelect={(e: any) => handleChange(e.value, 'access_profile')}
                                        placeholder={'Perfil de acesso'}
                                    />
                                    <InputSelect
                                        key={(Math.random() + 1).toString(36).substring(7)}
                                        /*selected={statusOptions.filter((status: any) => status.value === state.status)}*/
                                        selected={state.status}
                                        required
                                        label={'Status'}
                                        className={'md:col-span-2 xs:col-span-12'}
                                        options={statusOptions}
                                        onChangeSelect={(e: any) => handleChange(e.value, 'status')}
                                        placeholder={'Status'}
                                    />
                                </div>
                            </div>
                        </>
                }
            </FormLayout>
            <Modal
                size={'mds'}
                title={"Vincular Documento ao Imóvel"}
                footer={true}
                btnOnclickName={'Vincular'}
                onClick={() => onClickModal()}
                btnRowReverse={true}
                {...modalProps}
            >
                <InputSelect
                    isMulti={true}
                    key={(Math.random() + 1).toString(36).substring(7)}
                    value={state.properties}
                    label={'Selecione o(s) imóvel(is)'}
                    className={'content-center'}
                    options={propertiesOptions}
                    setValue={(e: any) => handleChange(e, 'properties')}
                    placeholder={'Selecione o(s) imóvel(is)'}
                />
            </Modal>
        </>
    )
}