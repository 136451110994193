import {useParams} from "react-router";
import React, {useEffect, useState} from "react";
import userApi from "../../../services/userApi";
import filesApi from "../../../services/filesApi";
import ListClean from "../../../components/layout/LayoutListClean";
import CardProperty from "../../../components/CardProperty";
import Alert from "../../../helpers/Alert";

export default function ListPropertiesFiles(){
    const params = useParams();
    console.log(params);
    const [properties, setproperties] = useState([]);
    const [loading, setLoading] = useState(true);

    async function getProperties() {
        const {data, isError} = await filesApi.getProperties(params.id)
        if (!isError){
            setproperties(data)
        }
        setLoading(false);
    }

    useEffect(() => {getProperties()},[]);

    async function onClickUnlinkProperty(propertyData: any) {
        const confirm = await Alert.confirm(
            'Tem certeza que deseja desnvicular esse imóvel?',
            `Desvincular Imóvel?`
        );
        if (!confirm) return;
        setLoading(true)
        const {data, isError} = await filesApi.unlinkProperties(params.id, propertyData.id)
        if (!isError){
            const newProperties = [...properties];
            setproperties(newProperties.filter((newProp: any) => newProp.id !== propertyData.id));
        }
        setLoading(false)
    }

    return(
        <ListClean client title={'Imóveis Vinculados'} urlBack={`/pastas/${params.folderId}/arquivos/formulario/${params.id}`}>
            {
                loading ?
                    <span className="flex items-center justify-center h-7">
                        <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-border" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </span>
                    :
                    <div className={'flex flex-col gap-5'}>
                        {
                            properties.length ?
                                properties.map((property) => {
                                    return(
                                        <>
                                            <CardProperty
                                                property={property}
                                                onDelete={onClickUnlinkProperty}
                                            />
                                        </>
                                    )
                                })

                                :
                                <div className={'flex pl-4 bg-[#1C1C24] justify-center  items-center rounded-md w-full border border-border text-system-gray-suave h-[3rem]'}>
                                    Nenhum imóvel vinculado
                                </div>
                        }

                    </div>
            }
        </ListClean>
    )
}