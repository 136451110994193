import * as React from "react";
import type { SVGProps } from "react";
const SvgAddCircleHalfDot = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '1em'}
    height={props.height || '1em'}
    viewBox="0 0 22 22"
    {...props}
  >
    <g fill="none" stroke="CurrentColor" strokeLinecap="round" strokeWidth={2}>
      <path d="M1 11A10 10 0 1 0 11 1" data-name="Caminho 320" />
      <path
        strokeLinejoin="round"
        d="M3.649 4.079q.208-.22.428-.428m2.87-1.922q.273-.122.553-.229m-6 6q.108-.283.232-.558"
        data-name="Caminho 321"
      />
      <path strokeLinejoin="round" d="M11 7v8m4-4H7" data-name="Caminho 322" />
    </g>
  </svg>
);
export default SvgAddCircleHalfDot;
