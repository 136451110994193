

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import {useRef} from "react";

import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import './index.css';
import { GoDotFill } from "react-icons/go";
import interactionPlugin from '@fullcalendar/interaction';
import React from "react";

export interface IFullCalendarComponent {
    calendarData: any;
    onClickDay: any;
    functionPrev: any;
    functionNext: any;
    getDataCalendar?: any;
}
export default function FullCalendarComponent({
  calendarData,
  onClickDay,
  functionPrev,
  functionNext,
}:IFullCalendarComponent) {
    const hoje = new Date().toISOString().slice(0, 10);

    const calendarRef = useRef<FullCalendar>(null!);
    function renderEventContent(eventInfo: any) {
        /*let corDotCalendario = ''

        if (eventInfo.event.extendedProps.concluido){
            corDotCalendario = '#28a44b'
        }else{
            corDotCalendario = '#c22929'
        }*/

        return (
            <div className={'text-[#061f46] flex items-center'}>
                <GoDotFill size={17} color={'#E0A422'}/>
            </div>
        )
    }

    async function next(e?: any) {
        functionNext();
        let currentDate = calendarRef.current.getApi();
        currentDate.next();


    }

    /*const next = (e?: any) => {
        let currentDate = calendarRef.current.getApi();
        currentDate.next();
        functionNext();

    }*/
    const prev = (e?: any) => {
        functionPrev(e);
        let currentDate = calendarRef.current.getApi();
        currentDate.prev();


    }

    return(
        <FullCalendar
            /*initialDate={new Date().toISOString().slice(0, 10)}*/
            selectable
            titleFormat={{ year: 'numeric', month: 'long' }}
            dayHeaderFormat={{weekday:'narrow'}}
            ref={calendarRef}
            locale={'pt-br'}
            plugins={[ dayGridPlugin, interactionPlugin ]}
            initialView="dayGridMonth"
            dateClick={(info: any) => onClickDay(info)}
            contentHeight={460}
            expandRows={true}
            eventContent={renderEventContent}
            eventClick={(info: any) => onClickDay(info)}
            events={calendarData}
            customButtons={{
                prev: {
                    click: function(info) {
                        prev(info)
                    },
                },
                next: {
                    click: function(ev) {
                        next(ev)
                    },
                },
            }}
            /*events={
                (fetchInfo, successCallback, failureCallback) => getDataCalendar(fetchInfo, successCallback, failureCallback)
            }*/
            buttonText={
                {
                    today:    'Hoje',
                    month:    'Mês',
                    week:     'Semana',
                    day:      'Dia',
                    list:     'Lista'
                }
            }
            headerToolbar={
                {
                    start: 'prev', // will normally be on the left. if RTL, will be on the right
                    center: 'title',
                    end: 'next' // will normally be on the right. if RTL, will be on the left
                }
            }
            dayCellClassNames={'teste'}
            dayCellContent={function(arg: { dayNumberText: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; isToday: any; }){
                return(
                    <div className={'flex w-full items-center justify-between'}>
                        <div className={'days-header font-semibold'}>{arg.dayNumberText}</div>
                        {arg.isToday ? <div className={'text-[16px] font-bold'}>Hoje</div> : ''}
                    </div>
                )
            }}
        />
    )
}