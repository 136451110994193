import menus from "../../../router/menus";
import React, {createElement, useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import Storage from "../../../helpers/Storage";
import useAppContext from '../../../hooks/useAppContext';

import {Analytics, File, Home, House, Settings, UploadSquare, UserAccount, UserSquare} from "../../Icons";
import authApi from "../../../services/authApi";
import {CgLogOut} from "react-icons/cg";
export default function SideBar() {
    const [loading, setLoading] = useState(true);
    const appContext = useAppContext();
    const { pathname: currentPath } = useLocation();
    const validatePage = currentPath.split('/')[1];
    const [menusFinal, setMenusFinal]: any = useState([]);
    const menusData = Storage.getUserMenu();
    const userData = Storage.getUserData();

    useEffect(() => {
        setLoading(true);
        // @ts-ignore
        if (userData.usuario.type === 'admin'){
            if (menusData) {
                appContext.setMenus(menusData);
                setMenusFinal(menusData)
            }
        }else{
            setMenusFinal(menus)
        }

        setLoading(false);
    },[]);

    const Icon = (title: string) => {
        let icon: any = null;
        switch (title) {
            case 'Home':
                icon = <Home />;
                break;
            case 'Dashboard':
                icon = <Analytics/>;
                break;
            case 'Cadastro de Cliente':
                icon = <UserAccount/>;
                break;
            case 'Cadastro de Usuário':
                icon = <UserSquare/>;
                break;
            case 'Cadastro de Imóveis':
                icon = <House/>;
                break;
            case 'Arquivos':
                icon = <UploadSquare/>;
                break;
            case 'Configurações':
                icon = <Settings/>;
                break;
        }
        return icon;
    }

    async function logout(){
        const {data, isError} = await authApi.logout();
        Storage.removeApiToken();
        Storage.removeUserData();
        Storage.removeUserMenuData();
        window.location.href = '/';
        console.log('aki')
    }

    return(
        <aside id="default-sidebar" className="w-64 h-full md:block xs:hidden" aria-label="Sidebar">
            <div className="h-full py-4 overflow-y-auto bg-system-blue-medium ">
                <ul className="space-y-4 font-medium">
                    {
                        loading ?
                            <div>
                                <span className="flex items-center justify-center h-7">
                                    <svg className="animate-spin -ml-1 mr-3 h-[20px] w-[20px] text-border" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>
                                </span>
                            </div>
                            :
                            <>
                                {
                                    menusFinal.map((menu: any) => {

                                        return (
                                            <li>
                                                <Link to={menu.path}
                                                      className={`flex items-center p-3 ${'/' + validatePage === menu.path ? 'text-system-primary border-l-4 border-system-primary' : 'text-[#B5B5BE]'}   group hover:text-system-primary`}>
                                                    <span className={'text-[20px]'}>{Icon(menu.title)}</span>
                                                    <span className="ms-3">{menu.title}</span>
                                                </Link>
                                            </li>
                                        )
                                    })
                                }
                                <li >
                                    <button onClick={() => logout()} className={`w-full flex items-center p-3 group hover:text-system-primary text-[#B5B5BE]`}>
                                        <CgLogOut size={25} /> <span className="ms-1">Sair</span>
                                    </button>
                                </li>
                            </>
                    }
                    {
                        /*menusFinal.map((menu: any) => {
                            return(
                                <li>
                                    <Link to={menu.path} className={`flex items-center p-3 ${'/'+validatePage === menu.path ? 'text-system-primary border-l-4 border-system-primary' : 'text-[#B5B5BE]'}   group hover:text-system-primary`}>
                                        <menu.icon width={20} height={20} />
                                        <span className="ms-3">{menu.title}</span>
                                    </Link>
                                </li>
                            )
                        })*/
                    }
                </ul>
            </div>
        </aside>
    )
}