import FormLayout from "../../../components/layout/FormLayout";
import React, {useState} from "react";
import foldersApi from "../../../services/foldersApi";
import Input from "../../../components/inputs/Input";

export default function FormFolders() {
    const initialState = {
        id: '',
        name: '',
    }
    const [state, setState]: any = useState(initialState);
    const [loading, setLoading] = useState(false);

    const handleChange = (value: any, input: string) => {
        const newState = {...state}
        const inputValuesArray = input.split('.');
        if (inputValuesArray.length > 1) {
            newState[inputValuesArray[0]][inputValuesArray[1]] = value
        }else{
            newState[input] = value
        }
        setState(newState);
    };

    return(
        <>
            <FormLayout
                title={'Cadastro de Pastas'}
                urlReturn={'/pastas'}
                state={state}
                setState={setState}
                apiData={foldersApi}
                defaltValues={initialState}
                permission={'files.edit'}
            >
                {
                    loading ?
                        <span className="flex items-center justify-center h-7">
                            <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </span>
                        :
                        <>
                            <div className={'flex  w-full '}>
                                <div className={'sm:w-full grid p-5'}>
                                    <div className="grid grid-cols-12 gap-3">
                                        <Input
                                            label={'Nome da pasta'}
                                            value={state.name}
                                            required
                                            className={'md:col-span-12 xs:col-span-12'}
                                            setValue={(v) => handleChange(v, 'name')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                }
            </FormLayout>
        </>
    )
}