import {ITable} from "../../types";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import {PaginationNav1Presentation} from "./Pagination";

export default function Table({columns, data, loading, onClick, onPageChange, onPerPageChange, perPage}: ITable) {
    return loading ?
        (
            <span className="flex items-center justify-center h-20 p-5">
              <svg className="animate-spin -ml-1 mr-3 h-[30px] w-[30px] text-system-primary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </span>
        )
            :
        (
            <>
                <table className="w-full table-auto text-left">
                    <TableHeader columns={columns}/>
                    {
                        data?.data ?
                            <TableBody columns={columns} tableData={data?.data} onClick={onClick}/>
                            :
                            "Nenhuma informação encontrada"
                    }
                </table>
                <footer className={'p-5 border-t border-system-gray-gray80 flex gap-4 bottom-10'}>
                    <PaginationNav1Presentation
                        page={data?.current_page}
                        onPageChange={onPageChange}
                        totalPaginas={data?.totalPage ?? data?.last_page}
                        lastPage={data?.last_page}
                    />
                    <select onChange={e => onPerPageChange(e.target.value)} defaultValue={perPage} className={'p-1 rounded bg-system-blue-dark  w-[5rem]'}>
                        <option value={10} >10</option>
                        <option value={15} >15</option>
                        <option value={25} >25</option>
                        <option value={50}>50</option>
                    </select>
                </footer>
            </>
        )
}