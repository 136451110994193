import userApi from "../../services/userApi";
import ListPageLayout from "../../components/layout/ListPageLayout";

export default function Client() {
    return(
        <ListPageLayout
            title={'Cadastro de Clientes'}
            urlCreate={'/clientes/formulario'}
            queries={{['where']: 'type,=,cliente' }}
            collunSearch={'name'}
            permission={'client.edit'}
            table={
                {
                    loader: userApi.all,
                    columns: [
                        { label: "Nome", index: "name", type: 'string'},
                        { label: "CPF", index: "cpf", type: 'string'},
                        { label: "Telefone", index: "phone", type: 'phone'},
                        { label: "E-mail", index: "email", type: 'string'},
                        { label: "Perfil", index: "access_profile", type: 'string'},
                    ]
                }
            }
        />
    )
}